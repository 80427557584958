import React, {
    Fragment,
    useEffect,
    useContext,
    useState,
    useCallback,
} from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import { FaSync } from "react-icons/fa";

import SubHeader from "../Layout/SubHeader";
import OrdersTable from "../Orders/OrdersTable";
import InfoModal from "../Layout/InfoModal";
import CancelOrdersButton from "../Orders/CancelOrdersButton";
import FinishPartialStockOrdersButton from "./FinishPartialStockOrders";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import useSortableData from "../../hooks/SortableData";

import { filterGroupToQuery, filterDateToQuery } from "../../helpers/Query";
import { sortSeletedOrdersFromSortedOrders } from "../../helpers/SortableOrders";
import { getDefaultOrderFiltersSchema, dateLimitedFilterSchema, dateFilterSchema } from "../../helpers/Filters";

const IncompleteOrders = () => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [lastLoadTime, setLastLoadTime] = useState(new Date());

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [procesingDownload, setProcesingDownload] = useState(false);

    const [infoModalData, setInfoModalData] = useState(null);

    const [orderFilters, setOrderFilters] = useState({
        ...getDefaultOrderFiltersSchema(salesShopGroup),
        statuses: null,
        partialStockStatuses: [
            { id: "pending", name: "pending", value: true },
            { id: "downloaded", name: "downloaded", value: false },
            { id: "processed", name: "processed", value: false },
        ],
        groups: ["partialStockStatuses", "shippingTypes", "brands", "shopGroups"],
    });

    const selectedPartialStockStatus = orderFilters.partialStockStatuses.find((status) => status.value);

    const { items, requestSort, sortConfig } = useSortableData(orders, {
        direction: "ascending",
        key: "createdAt",
    });

    const handleOrderSelect = useCallback(
        (orderId, checked) => {
            //console.log("Cambia item ", orderId, " checked ", checked);
    
            if (checked) {
                setSelectedOrders((oldSelectedOrders) => {
                    if (oldSelectedOrders.includes(orderId)) {
                        return oldSelectedOrders;
                    } else {
                        return [...oldSelectedOrders, orderId];
                    }
                });
            } else {
                setSelectedOrders((oldSelectedOrders) =>
                    oldSelectedOrders.filter((id) => id !== orderId)
                );
            }
        },
        [],
    );

    const fetchOrders = useCallback(
        () => {
        setOrdersLoading(true);

        let queryFilters = "?group=partialStock&";
        //queryFilters += (filterGroupToQuery("status", orderFilters.statuses));
        queryFilters += (filterGroupToQuery("partialStockStatus", orderFilters.partialStockStatuses));
        queryFilters += filterGroupToQuery("brand", orderFilters.brands);
        queryFilters += filterGroupToQuery(
            "shippingType",
            orderFilters.shippingTypes
        );
        queryFilters += filterDateToQuery("date", orderFilters.date);
        queryFilters += filterGroupToQuery("shopGroups", orderFilters.shopGroups);

        axios
            .get(env.webApiBaseUrl + "/orders" + queryFilters, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
            })
            .then((ordersResponse) => {
                setOrders(ordersResponse.data);
                setSelectedOrders(ordersResponse.data.map( order => order._id));
            })
            .catch((error) => {
                console.error(error);
                setInfoModalData({
                    type: "error",
                    msg: "No pude recuperar las ventas.",
                });
            })
            .finally(() => {
                setOrdersLoading(false);
                setLastLoadTime(new Date());
            });
    }, [env, csrfToken, user, setOrdersLoading, setOrders, orderFilters]);

    const handleDownload = useCallback(
        () => {
            setProcesingDownload(true);
    
            const reportRequest = {
                selectedOrders: sortSeletedOrdersFromSortedOrders(selectedOrders, items),
            };
    
            console.log("Generating partial orders report for: ", reportRequest.selectedOrders);
    
            axios
                .post(env.webApiBaseUrl + "/orders/partials", reportRequest, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                        responseType: 'blob',
                    },
                    withCredentials: true,
                })
                .then((reportResponse) => {
                    const fileContent = reportResponse.data;

                    const filename = "parciales-" + Date.now() + ".csv";
                    fileDownload(fileContent, filename, "text/csv", "\uFEFF");
    
                    setSelectedOrders([]);
                    fetchOrders();
                })
                .catch((error) => {
                    console.error(error);
                    setInfoModalData({
                        type: "error",
                        msg: "No pude generar el reporte.",
                    });
                })
                .finally(() => {
                    setProcesingDownload(false);
                });
        },
        [csrfToken, env.webApiBaseUrl, fetchOrders, items, selectedOrders, user.token],
    );

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders, orderFilters]);

    useEffect(() => {
        const cleanSelectedOrders = () => {
            // Revisar que todo lo que está en selectedOrders exista
        };

        cleanSelectedOrders();
    }, [orders]);

    useEffect(() => {
        setOrderFilters(oldOrderFilters => {
            const partialStockStatus = oldOrderFilters.partialStockStatuses.find((status) => status.value);
            const dateFilters = partialStockStatus && partialStockStatus.id === "processed"
                ? dateLimitedFilterSchema
                : dateFilterSchema;

            const oldSelectedDate = oldOrderFilters.date;
            if (dateFilters.dateRangeOptions.find((range) => range.id === oldSelectedDate.range)) {
                dateFilters.date = oldOrderFilters.date;
            }

            return {
                ...oldOrderFilters,
                ...dateFilters,
            };
        });
    }, [orderFilters.partialStockStatuses]);

    return (
        <div>
            <SubHeader>
                <Button
                    className="float-end ms-2 mt-1 mb-2"
                    onClick={() => fetchOrders()}
                >
                    <FaSync style={{ marginBottom: "3px" }} />
                </Button>

                {selectedPartialStockStatus && selectedPartialStockStatus.id === "pending"
                ? <Button
                    variant="primary"
                    className="float-end mt-1 mb-2 ms-2"
                    disabled={!selectedOrders.length || procesingDownload}
                    onClick={() => handleDownload()}
                >
                    Descargar reporte ventas parciales
                    {procesingDownload ? (
                        <Fragment>
                            <Spinner
                                className="ms-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="visually-hidden">Cargando...</span>
                        </Fragment>
                    ) : (
                        <Badge className="ms-2" bg="light" text="dark">
                            {selectedOrders.length}
                        </Badge>
                    )}
                </Button>
                :null}

                {selectedPartialStockStatus && selectedPartialStockStatus.id === "downloaded"
                ? <FinishPartialStockOrdersButton
                    items={items}
                    selectedOrders={selectedOrders}
                    setInfoModalData={setInfoModalData}
                    setSelectedOrders={setSelectedOrders}
                    fetchOrders={fetchOrders}
                />
                :null}

                <CancelOrdersButton
                    items={items}
                    selectedOrders={selectedOrders}
                    setInfoModalData={setInfoModalData}
                    setSelectedOrders={setSelectedOrders}
                    fetchOrders={fetchOrders}
                    isPartialOrder={true}
                />

                <h2>
                    VENTAS CON FALTA DE STOCK
                    <span className="quantities">
                        <Badge className="ms-3" bg="dark">
                            {orders.length} ventas
                        </Badge>
                    </span>
                </h2>
                Última actualización: {lastLoadTime.toLocaleString("es-CL")}
            </SubHeader>
            <br />

            <Container fluid>
                {infoModalData ? (
                    <InfoModal
                        closeButton={true}
                        type={infoModalData.type}
                        size={infoModalData.size}
                        msg={infoModalData.msg}
                        setMsg={setInfoModalData}
                    />
                ) : null}

                <OrdersTable
                    orders={items}
                    sortConfig={sortConfig}
                    requestSort={requestSort}
                    selectedOrders={selectedOrders}
                    handleOrderSelect={handleOrderSelect}
                    orderFilters={orderFilters}
                    setOrderFilters={setOrderFilters}
                    ordersLoading={ordersLoading}
                    vistaSinStock={true}
                    setInfoModalData={setInfoModalData}
                />
            </Container>
        </div>
    );
};

export default IncompleteOrders;
