import React from 'react';
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { FaBan } from "react-icons/fa";

import SubHeader from "../Layout/SubHeader";

import { getPrettyShopGroupName } from '../../helpers/PrettyShopNames';

const ManualPackingsSubHeader = ({salesShopGroup, orderQuantity, itemQuantity, selectedOrderQuantity, printingPacking, clearOrders, handlePrintPackings}) => {
    return (  
        <SubHeader>
            <Button
                variant="danger"
                className="float-end ms-2 mt-1 mb-2"
                onClick={clearOrders}
            >
                <FaBan style={{ marginBottom: "3px" }} />
            </Button>
            <Button
                className="float-end mt-1 mb-2"
                variant="warning"
                disabled={!selectedOrderQuantity || printingPacking}
                onClick={handlePrintPackings}
            >
                Descargar etiquetas para packing

                <Badge className="ms-2" bg="light" text="dark">
                    {selectedOrderQuantity}
                </Badge>
            </Button>
            <h2>
                VENTAS PARA PACKING
                <span className="quantities">
                    <Badge className="ms-3" bg="dark">
                        {orderQuantity} ventas
                    </Badge>
                    <Badge className="ms-2" bg="info">
                        {itemQuantity} ítems
                    </Badge>
                </span>
            </h2>
            <strong>Origen:</strong> {getPrettyShopGroupName(salesShopGroup)}
        </SubHeader>
    );
}
 
export default ManualPackingsSubHeader;