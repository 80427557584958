import React, { useRef, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LoadingSpinner from "../Layout/LoadingSpinner";

const AddOrderForm = ({orderLoading, orderId, validated, handleChange, handleSubmit, mode}) => {
    const inputLabel = mode && mode === "auto" 
        ? "Seleccionar venta"
        : "Agregar venta a la lista (todas deben tener el mismo tipo de envío)";

    const buttonLabel = mode && mode === "auto"
        ? "Seleccionar"
        : "Agregar";

    const inputRef = useRef(null);

    useEffect(() => {
        const focus = () => {
            if (inputRef.current) {
                inputRef.current.focus();
                //console.log("Input focus", inputRef.current.value);
            }
        }
        setTimeout(focus, 100);
    }, [orderId]);

    return (
        <Container fluid style={{ height: "8rem" }}>
            <div className="w-100 h-100 p-4 border">
                {orderLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Form
                        className="h-100"
                        validated={validated}
                        onSubmit={(event) => handleSubmit(event)}
                    >
                        <Row>
                            <Col>
                                <Form.Label>
                                    { inputLabel }
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm={10}>
                                {/*<Form.Label>Agregar venta</Form.Label>*/}
                                <Form.Control
                                    required
                                    autoFocus
                                    ref={inputRef}
                                    name="orderCode"
                                    type="text"
                                    placeholder="Código de venta"
                                    value={orderId}
                                    onChange={(event) => handleChange(event)}
                                    maxLength={24}
                                />
                            </Form.Group>
                            <Col sm={2}>
                                <Button className="w-100" type="submit" variant="primary">
                                    { buttonLabel }
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </div>
        </Container>
    );
}
 
export default AddOrderForm;