import React from 'react';

import AlertImg from '../../assets/alert.png';

const Alert = () => {
    return (
        <img src={AlertImg} width="100" height="100" alt="ATENCIÓN" />
    );
}
 
export default Alert;