import { BrowserRouter as Router } from 'react-router-dom';

import SecuredContent from './components/Layout/SecuredContent';
import SiteProvider from './context/SiteContext';
import UserProvider from './context/UserContext';
import PrinterProvider from './context/PrinterContext';

import AnimatedPage from './components/Layout/AnimatedPage';
import Header from './components/Layout/Header';

import './App.scss';

function App() {
  return (
    <Router>
      <SiteProvider>
        <UserProvider>
          <PrinterProvider>
            <Header />

            <SecuredContent>
              <AnimatedPage />
            </SecuredContent>

          </PrinterProvider>
        </UserProvider>
      </SiteProvider>
    </Router>
  );
}

export default App;
