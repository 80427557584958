import React, { useContext } from 'react';

import Login from './Login';

import { UserContext } from '../../context/UserContext';

const SecuredContent = ({ children }) => {
    const { user } = useContext(UserContext);

    return (
        <div>
            {user
            ? <div>
                {children}
            </div>
            : <Login />
            }
            
        </div>
    );
}
 
export default SecuredContent;