import React, { useCallback, useEffect, useContext, useState } from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { SiteContext } from "../../context/SiteContext";

import { getShopGroupsSchema } from "../../helpers/Filters";

const OriginForm = () => {
    const { salesShopGroup, setSalesShopGroup } = useContext(SiteContext);

    const [orderFilters, setOrderFilters] = useState({
        ...getShopGroupsSchema(salesShopGroup),
    });

    const handleRadioChange = useCallback((group, id, value) => {
        setOrderFilters((oldFilters) => {
            const newFilters = {
                ...oldFilters,
                [group]: oldFilters[group].map((item) => ({
                    id: item.id,
                    name: item.name,
                    value: item.id === id ? value : false,
                })),
            };
            return newFilters;
        });
    }, []);

    useEffect(() => {
        const updateShopGroup = () => {
            if (orderFilters.shopGroups) {
                const selectedShopGroup = orderFilters.shopGroups.find((shopGroup) => shopGroup.value);
                setSalesShopGroup(selectedShopGroup.id);
            }
        }

        updateShopGroup();
    }, [orderFilters.shopGroups, setSalesShopGroup]);

    return (
        <div className="orderFilters w-100 h-100 p-3 pt-1">
            <Form
                className="h-100"
                validated={false}
            >
                <Row className="mt-3">
                    <Col md={12}>
                        <Form.Group className="form-group">
                            <Form.Label>Origen</Form.Label>
                            <Row>
                                {orderFilters.shopGroups.map((shopGroup) => (
                                    <Col md key={"shopGroup_" + shopGroup.id}>
                                        <Form.Check
                                            type="radio"
                                            className=""
                                            name="shopGroup"
                                            id={"filterShopGroup_" + shopGroup.id}
                                            label={shopGroup.name}
                                            checked={shopGroup.value}
                                            disabled={shopGroup.disabled || false}
                                            onChange={(event) =>
                                                handleRadioChange(
                                                    "shopGroups",
                                                    shopGroup.id,
                                                    event.currentTarget.checked
                                                )
                                            }
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
 
export default OriginForm;