import React, { useCallback, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FaReceipt } from 'react-icons/fa';
import axios from "axios";
import fileDownload from "js-file-download";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

const Receipt = ({orderId}) => {
    const { env, csrfToken } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [receiptLoading, setReceiptLoading] = useState(false);

    const downloadReceipt = useCallback(
        () => {
            setReceiptLoading(true);

            axios.get(
                env.webApiBaseUrl + "/orders/" + orderId + "/receipt",
                {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                    responseType: "blob",
                    timeout: 60000,
                }
            )
            .then ((response) => {
                const filename = "receipt-" + orderId + "-" + Date.now() + ".pdf";
                fileDownload(response.data, filename);
            })
            .catch ((e) => {
                console.error(
                    "Problemas al obtener la boleta: ",
                    e
                );
            })
            .finally(() => {
                setReceiptLoading(false);
            });
        },
        [csrfToken, env.webApiBaseUrl, orderId, user.token],
    );

    return (
        <Button size="sm" variant="primary" onClick={downloadReceipt}>
            {receiptLoading
            ? <Spinner animation="border" role="status" size="sm"/>
            : <FaReceipt className="lead"/>
            }
        </Button>
    );
}
 
export default Receipt;