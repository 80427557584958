import React, { useCallback, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

import AddItemForm from './AddItemForm';
import OrderItemsModalHeader from './OrderItemsModalHeader';
import InfoModalContent from '../Layout/InfoModalContent';
import ItemsTable from './ItemsTable';

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

const OrderItemsModal = ({packingOrder, setPackingOrder, handleOrderPacked}) => {
    
    const { env, csrfToken } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [packedItems, setPackedItems] = useState(
        packingOrder.items.map((orderItem) => ({
            _id: orderItem._id, 
            sku: orderItem.variant.sku, 
            packed: 0,
            damaged: 0,
        }))
    );
 
    const [validated, setValidated] = useState(false);
    const [itemSku, setItemSku] = useState("");

    const [infoMessageData, setInfoMessageData] = useState({});

    const setPackedItemsInOrder = useCallback(
        (order, packedItems) => {
            setInfoMessageData({
                type: "loading",
            });

            const request = {
                packedItems: packedItems,
            };

            axios
                .post(env.webApiBaseUrl + "/orders/" + order._id + "/packedItems", request, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                })
                .then((orderResponse) => {
                    const data = orderResponse.data;
                    if (!data.updatedItems) {
                        setInfoMessageData({
                            type: "error",
                            msg: "Error guardar estado de la venta.",
                            retryButton: true,
                        });
                    } else {
                        order.items = data.updatedItems;
                        order.prevStatus = order.status;
                        order.status = data.updatedOrderStatus;
                        setInfoMessageData(null);
                        handleOrderPacked(order);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setInfoMessageData({
                        type: "error",
                        msg: "No pude guardar el estado de la venta.",
                        retryButton: true,
                    });
                })
                .finally(() => {
                });
        },
        [csrfToken, env.webApiBaseUrl, handleOrderPacked, user.token],
    );

    const handleCancel = useCallback(
        () => {
            setPackingOrder(null);
        },
        [setPackingOrder],
    );

    const handleAddItem = useCallback(
        (sku, itemStatus) => {
            const orderItem = packingOrder.items
                .find((item) => (item.variant.sku ? (item.variant.sku + "").toUpperCase() : item.variant.sku) === sku);

            if (orderItem) {
                const packedItem = packedItems.find((item) => item._id === orderItem._id);
                const packedQuantity = packedItem.packed;
                const damagedQuantity = packedItem.damaged;

                const availableQuantity = orderItem.quantityToPack || 0;
                const processedQuantity = packedQuantity + damagedQuantity;

                if (processedQuantity < availableQuantity) {
                    setPackedItems(oldPackedItems => ([
                        ...oldPackedItems.filter((item) => item._id !== orderItem._id),
                        { 
                            ...oldPackedItems.find((item) => item._id === orderItem._id),
                            [itemStatus]: oldPackedItems.find((item) => item._id === orderItem._id)[itemStatus] + 1,
                        }
                    ]));
                    setValidated(false);
                }
                else {
                    setInfoMessageData({
                        type: "error",
                        msg: "Se están agregando más ítems que los que contiene la venta!",
                        retryButton: true,
                    });
                }
            }
            else {
                setInfoMessageData({
                    type: "error",
                    msg: "SKU " + (sku || "") + " inválido",
                    retryButton: true,
                });
            }

            setItemSku("");
        },
        [packedItems, packingOrder],
    );

    const handleSubmit = useCallback(
        (event, itemStatus) => {
            event.preventDefault();
            setValidated(true);

            handleAddItem(itemSku, itemStatus);
        },
        [handleAddItem, itemSku]
    );

    const handleChange = useCallback(
        (event, itemStatus) => {
            const translatedSku = (event.target.value || "").toUpperCase();
            setItemSku(translatedSku);

            // auto submit
            if (translatedSku.length === 18) {
                handleAddItem(translatedSku, itemStatus);
            }
        },
        [handleAddItem],
    );

    useEffect(() => {
        const checkAutoClose = () => {
            if (packedItems.reduce((a,b) => a + b.packed, 0) === packingOrder.items.reduce((a,b) => a + b.quantityToPack, 0)) {
                setPackedItemsInOrder(packingOrder, packedItems);
            }
        }

        checkAutoClose();
    }, [packedItems, packingOrder, setPackedItemsInOrder]);

    return (
        <Modal 
            size="xl"
            backdrop="static"
            show={packingOrder !== null} 
            onHide={handleCancel}
        >
            <OrderItemsModalHeader
                packingOrder={packingOrder}
                packedItems={packedItems}
                handleCancel={handleCancel}
                setPackedItemsInOrder={setPackedItemsInOrder}
                infoMessageData={infoMessageData}
            />

            <Modal.Body style={{ height: "80vh", overflowY: "scroll", overflowX: "auto" }}>
                {infoMessageData && infoMessageData.type
                ? <div className="text-center w-50 m-auto">
                    <InfoModalContent
                        type={infoMessageData.type}
                        closeButton={infoMessageData.closeButton}
                        retryButton={infoMessageData.retryButton}
                        msg={infoMessageData.msg}
                        setMsg={setInfoMessageData}
                    />
                </div>
                : <div>
                    <AddItemForm 
                        itemSku={itemSku}
                        validated={validated}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                    />

                    <br />

                    {packingOrder ?
                        <ItemsTable 
                            packingOrder={packingOrder}
                            packedItems={packedItems}
                        />
                    :null
                    }
                </div>
                }
            </Modal.Body>
        </Modal>
    );
}
 
export default OrderItemsModal;