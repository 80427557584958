export const getShopGroupFromOrder = (order) => {
    if (order.origin === "PrestaShop") {
        return "PZ";
    }
    else if (order.origin === "Longtail") {
        return "Longtail";
    }
    else {
        return order.shop;
    }
};
