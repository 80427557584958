import React, { useCallback, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

import AddItemForm from './CreditNoteForm';
import CreditNoteItemsModalHeader from './CreditNoteItemsModalHeader';
import InfoModalContent from '../Layout/InfoModalContent';
import ItemsTable from './ItemsTable';

import { getProductosFromDocumentedSale } from '../../helpers/DocumentedSale';

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

const CreditNoteItemsModal = ({order, setOrder, fetchOrders}) => {
    
    const { env, csrfToken } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [documentedSale, setDocumentedSale] = useState(null);
    const [returnedItems, setReturnedItems] = useState([]);
    const [creditNoteReason, setCreditNoteReason] = useState("client-return");
 
    const [validated, setValidated] = useState(false);
    const [itemSku, setItemSku] = useState("");

    const [infoMessageData, setInfoMessageData] = useState({
        type: "loading",
        msg: "Obteniendo detalles de la boleta",
    });

    const fetchDocumentedSale = useCallback(
        (order) => {
            axios
            .get(env.webApiBaseUrl + "/orders/" + order._id + "/documentedSale", {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
            })
            .then((documentedSaleResponse) => {
                const data = documentedSaleResponse.data;
                if (!data) {
                    setInfoMessageData({
                        type: "error",
                        msg: "No pude obtener los datos de la boleta",
                        retryButton: false,
                    });
                } else {
                    setDocumentedSale(data);
                    setInfoMessageData(null);
                }
            })
            .catch((error) => {
                console.log(error);
                setInfoMessageData({
                    type: "error",
                    msg: "No pude obtener los detalles de la boleta",
                    retryButton: false,
                });
            })
            .finally(() => {
            });
        },
        [csrfToken, env.webApiBaseUrl, user.token]
    );

    const setReturnedItemsInOrder = useCallback(
        (order, returnedItems, creditNoteReason) => {
            setInfoMessageData({
                type: "loading",
            });

            const request = {
                creditNoteReason: creditNoteReason,
                quantitiesPerSkuReturned: returnedItems,
            };

            axios
                .post(env.webApiBaseUrl + "/orders/" + order._id + "/creditNote", request, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                })
                .then((creditNoteResponse) => {
                    order.prevStatus = order.status;
                    order.status = "returned";
                    setInfoMessageData({
                        type: 'success',
                        msg: "Nota de crédito generada."
                    });
                    fetchOrders();
                })
                .catch((error) => {
                    console.log(error);

                    const msg = error.response && error.response.data && error.response.data.msg 
                        ? <div>
                            <span style={{ whiteSpace: "pre-line" }}>
                                {error.response.data.msg}
                            </span>
                        </div> 
                        : "Error al generar nota de crédito.";

                    setInfoMessageData({
                        type: "error",
                        msg: msg,
                        retryButton: true,
                    });
                })
                .finally(() => {
                });
        },
        [csrfToken, env.webApiBaseUrl, fetchOrders, user.token],
    );

    const handleCancel = useCallback(
        () => {
            setOrder(null);
        },
        [setOrder],
    );

    const handleAddItem = useCallback(
        (sku, itemStatus) => {
            const saleItem = getProductosFromDocumentedSale(documentedSale).ventaItemListaDatosList
                .find((item) => item.sku != null && item.sku.toUpperCase() === sku.toUpperCase());

            if (saleItem) {
                const returnedItem = returnedItems.find((item) => item.sku === saleItem.sku);
                const returnedQuantity = returnedItem.returned;

                const availableQuantity = (saleItem.cantidad - saleItem.cantidadDevol) || 0;
                const processedQuantity = returnedQuantity;

                if (processedQuantity < availableQuantity) {
                    setReturnedItems(oldReturnedItems => ([
                        ...oldReturnedItems.filter((item) => item.sku !== saleItem.sku),
                        { 
                            ...oldReturnedItems.find((item) => item.sku === saleItem.sku),
                            [itemStatus]: oldReturnedItems.find((item) => item.sku === saleItem.sku)[itemStatus] + 1,
                        }
                    ]));
                    setValidated(false);
                }
                else {
                    setInfoMessageData({
                        type: "error",
                        msg: "Se están devolviendo más ítems que los que contiene la venta!",
                        retryButton: true,
                    });
                }
            }
            else {
                setInfoMessageData({
                    type: "error",
                    msg: "SKU " + (sku || "") + " inválido",
                    retryButton: true,
                });
            }

            setItemSku("");
        },
        [returnedItems, documentedSale],
    );

    const handleSubmit = useCallback(
        (event, itemStatus) => {
            event.preventDefault();
            setValidated(true);

            handleAddItem(itemSku, itemStatus);
        },
        [handleAddItem, itemSku]
    );

    const handleChange = useCallback(
        (event, itemStatus) => {
            const translatedSku = (event.target.value || "").toUpperCase();
            setItemSku(translatedSku);

            // auto submit
            if (translatedSku.length === 18) {
                handleAddItem(translatedSku, itemStatus);
            }
        },
        [handleAddItem],
    );

    useEffect(() => {
        const resetReturnedItems = () => {
            setReturnedItems(
                documentedSale
                ? getProductosFromDocumentedSale(documentedSale).ventaItemListaDatosList.map((item) => ({
                        sku: item.sku, 
                        returned: 0,
                    }))
                : []
            );
        };

        resetReturnedItems();
    }, [documentedSale]);

    useEffect(() => {
        fetchDocumentedSale(order);
    }, [fetchDocumentedSale, order]);

    return (
        <Modal 
            size="xl"
            backdrop="static"
            show={order !== null} 
            onHide={handleCancel}
        >
            <CreditNoteItemsModalHeader
                order={order}
                returnedItems={returnedItems}
                creditNoteReason={creditNoteReason}
                handleCancel={handleCancel}
                setReturnedItemsInOrder={setReturnedItemsInOrder}
                infoMessageData={infoMessageData}
            />

            <Modal.Body style={{ height: "80vh", overflowY: "scroll", overflowX: "auto" }}>
                {infoMessageData && infoMessageData.type
                ? <div className="text-center w-50 m-auto">
                    <br />
                    <InfoModalContent
                        type={infoMessageData.type}
                        closeButton={infoMessageData.closeButton}
                        retryButton={infoMessageData.retryButton}
                        msg={infoMessageData.msg}
                        setMsg={setInfoMessageData}
                    />
                </div>
                : <div>
                    <AddItemForm 
                        itemSku={itemSku}
                        validated={validated}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        creditNoteReason={creditNoteReason}
                        setCreditNoteReason={setCreditNoteReason}
                    />

                    <br />

                    <ItemsTable 
                        documentedSale={documentedSale}
                        returnedItems={returnedItems}
                    />
                </div>
                }
            </Modal.Body>
        </Modal>
    );
}
 
export default CreditNoteItemsModal;