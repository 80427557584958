import React from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import { FaRegCalendarAlt } from 'react-icons/fa';

const StatusHistory = ({statusHistory}) => {
    const popover =  (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Historial de estados</Popover.Header>
            <Popover.Body>
                <ul>
                {statusHistory.map((history, index) => (
                    <li key={index} className="mb-1">
                        <strong>{history.status}</strong> <br/>
                        {(new Date(history.updatedAt)).toLocaleString('es-CL')} <br/>
                        origen <em>{history.triggeredBy}</em><br/>
                        {history.userEmail
                        ? <span>
                            por <em>{history.userEmail}</em>
                        </span>
                        : null
                        }
                    </li>
                ))}
                </ul>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
            <Button size="sm" variant="primary">
                <FaRegCalendarAlt className="lead"/>
            </Button>
        </OverlayTrigger>
    );
}
 
export default StatusHistory;