export const getPrettyShopName = (shop) => {
    if (shop === "FalabellaGlobalProduction") {
        return "Falabella";
    }
    else if (shop === "paris.cl") {
        return "Paris"
    }

    return shop;
};

export const getPrettyShopGroupName = (shopGroup) => {
    if (shopGroup === "FalabellaGlobalProduction") {
        return "Falabella";
    }
    else if (shopGroup === "paris.cl") {
        return "Paris";
    }
    else if (shopGroup === "Longtail") {
        return "Tiendas";
    }

    return shopGroup;
};