import React, { useContext, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import axios from "axios";

import InfoModal from "./InfoModal";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

const Login = () => {
    const { env, csrfToken } = useContext(SiteContext);
    const { setUser } = useContext(UserContext);

    const defaultFormData = {
        email: "",
        pass: "",
    };

    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);
    const [workingMsg, setWorkingMsg] = useState({});

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.currentTarget.name]: event.currentTarget.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setValidated(true);

        const form = event.currentTarget;

        if (!form.checkValidity()) {
            return;
        }

        setWorkingMsg({
            type: "loading",
            msg: "ESPERA UN MOMENTO",
        });

        const apiBaseUrl = env.webApiBaseUrl;
        const loginRequest = {
            user: formData,
        };

        axios
            .post(apiBaseUrl + "/user/login", loginRequest, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                },
                withCredentials: true,
            })
            .then((resp) => {
                setWorkingMsg({});

                const loggedUser = {
                    userId: resp.data.userId,
                    email: resp.data.email,
                    token: resp.data.token,
                };

                setUser(loggedUser);

                setValidated(false);
                setFormData(defaultFormData);
            })
            .catch((error) => {
                setWorkingMsg({
                    type: "error",
                    msg: "No pude comprobar el usuario",
                    retryButton: true,
                });
            });
    };

    return (
        <Container>
            <InfoModal
                type={workingMsg.type}
                msg={workingMsg.msg}
                retryButton={workingMsg.retryButton}
                closeButton={workingMsg.closeButton}
                setMsg={setWorkingMsg}
            />

            <br />
            <br />
            <br />
            <br />
            <Row className="justify-content-center">
                <Form
                    noValidate
                    validated={validated}
                    name="loginForm"
                    className="loginForm"
                    onSubmit={(event) => handleSubmit(event)}
                >
                    <Card className="login text-center">
                        <Card.Header className="ps-5 pe-5">
                            <h3>INGRESA AQUÍ</h3>
                        </Card.Header>
                        <Card.Body>
                            <Row className="">
                                <Form.Group as={Col} sm={12} className="mb-3">
                                    <Form.Control
                                        required
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        value={formData.title}
                                        onChange={(event) =>
                                            handleChange(event)
                                        }
                                        maxLength={255}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="">
                                <Form.Group as={Col} sm={12} className="mb-3">
                                    <Form.Control
                                        required
                                        name="pass"
                                        type="password"
                                        placeholder="Password"
                                        value={formData.title}
                                        onChange={(event) =>
                                            handleChange(event)
                                        }
                                        maxLength={255}
                                    />
                                </Form.Group>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Button className="w-100" type="submit" variant="primary">
                                ACCEDER
                            </Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Row>
        </Container>
    );
};

export default Login;
