export const sortSeletedOrdersFromSortedOrders = (
    selectedOrders,
    sortedOrders
) => {
    let selectedSorted = [];

    for (const sortedOrder of sortedOrders) {
        if (selectedOrders.includes(sortedOrder._id)) {
            selectedSorted.push(sortedOrder._id);
        }
    }

    return selectedSorted;
};
