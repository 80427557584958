import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const GenericNotFound = () => {
    return (
        <Container>
            <Row className="justify-content-center">
                <h1>
                    La página que buscas no existe
                </h1>
            </Row>
        </Container>
    );
}
 
export default GenericNotFound;