import React, { Fragment, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaTools, FaSignOutAlt } from "react-icons/fa";

import { UserContext } from '../../context/UserContext';

import SelectPrinterButton from './SelectPrinterButton';

const Header = () => {
    const location = useLocation();

    const { user, revokeSession } = useContext(UserContext);

    const navigate = useNavigate();

    const gotoTools = () => {
        navigate("/tools");
    }

    return (
        <Fragment>
        <Navbar 
            className="justify-content-center border-bottom shadow"
            bg="white"
            expand="lg"
            collapseOnSelect={true}
        >
            <Container fluid>

                <Navbar.Brand className="bg-white py-2 px-0">
                    <img src="/img/logoGinoGrande.png" height={48} alt="Gino"/>
                </Navbar.Brand>

                {user
                ? <Fragment>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav 
                            className="justify-content-center w-100"
                            fill
                            justify
                            activeKey={location.pathname}
                            //defaultActiveKey={location.pathname}
                        >
                            <Nav.Item>
                                <LinkContainer to="/home">
                                    <Nav.Link href="/home">HOME</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/pickings">
                                    <Nav.Link eventKey="/pickings">PICKING</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/packings">
                                    <Nav.Link eventKey="/packings">PACKING</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/incomplete">
                                    <Nav.Link eventKey="/incomplete">SIN STOCK</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/dispatchs">
                                    <Nav.Link eventKey="/dispatchs">DESPACHO</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/processed">
                                    <Nav.Link eventKey="/processed">PROCESADAS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/cancelled">
                                    <Nav.Link eventKey="/cancelled">CANCELADAS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/returned">
                                    <Nav.Link eventKey="/returned">DEVUELTAS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                        </Nav>

                        <SelectPrinterButton />

                        <Button
                            className="headerButton ms-2"
                            variant="light"
                            onClick={() => gotoTools()}
                        >
                            <FaTools />
                        </Button>
                        
                        <div
                            className="ms-2 text-center"
                            //style={{ borderLeft: "solid 1px lightgray" }}
                        >
                            <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltipLogout" className="text-center">
                                        Cerrar sesión<br/>
                                        {user.email}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    className="headerButton"
                                    variant="light"
                                    onClick={() => revokeSession()}
                                >
                                    <FaSignOutAlt />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </Navbar.Collapse>
                </Fragment>
                : null
                }
                
            </Container>
        </Navbar>

        <br/>
        </Fragment>
    );
}
 
export default Header;