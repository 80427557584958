import React, { Fragment } from 'react';
import Badge from 'react-bootstrap/Badge';
import { NumericFormat } from 'react-number-format';

import OrderItemPackingStatus from './OrderItemPackingStatus';
import StatusHistory from './StatusHistory';
import ShippingLabel from './ShippingLabel';
import Receipt from './Receipt';
import CreditNote from './CreditNote';
import Picking from './Picking';

import { getExpectedPackingDate, orderCanHaveShippingLabel } from "../../helpers/Packing";
import { getPrettyShopName } from '../../helpers/PrettyShopNames';

const OrderField = ({ order, field, setInfoModalData }) => {
    return (
        <Fragment>
            {
                {
                    "shop":
                        <td>{getPrettyShopName(order.shop)}</td>,
                    "idShop":
                        <td className="text-center">
                            {order.idShop}
                            {order.status !== "pending"
                                ? <div className="pt-2">
                                    <Picking
                                        orderId={order._id}
                                    />
                                </div>
                                : null
                            }
                        </td>,
                    "clientName":
                        <td style={{ width: "15rem", wordWrap: "anywhere" }}>{order.clientName}</td>,
                    "createdAt":
                        <td className="text-center">
                            {(new Date(order.createdAt)).toLocaleString('es-CL')}
                        </td>,
                    "updatedAt":
                        <td className="text-center">
                            {(new Date(order.updatedAt)).toLocaleString('es-CL')}
                        </td>,
                    "shippingType":
                        <td className="text-center">
                            {order.shippingType}
                            {orderCanHaveShippingLabel(order)
                                ? <div className="pt-2">
                                    <ShippingLabel
                                        orderId={order._id}
                                        orderOrigin={order.origin}
                                        orderShop={order.shop}
                                        shippingType={order.shippingType}
                                        setInfoModalData={setInfoModalData}
                                    />
                                </div>
                                : null
                            }
                            {order.extraData &&
                                order.extraData.shipping &&
                                order.extraData.shipping.substatus === "buffered"
                                ? <Badge bg="info">
                                    PACKING {getExpectedPackingDate(order)}
                                </Badge>
                                : null
                            }
                        </td>,
                    "partialStockStatus":
                        <td className="text-center">
                            {order.partialStockStatus}
                            {order.partialStockStatusHistory && order.partialStockStatusHistory.length
                                ? <div className="pt-2">
                                    <StatusHistory
                                        statusHistory={order.partialStockStatusHistory}
                                    />
                                </div>
                                : null
                            }
                        </td>,
                    "status":
                        <td className="text-center">
                            {order.status}
                            {order.statusHistory && order.statusHistory.length
                                ? <div className="pt-2">
                                    <StatusHistory
                                        statusHistory={order.statusHistory}
                                    />
                                </div>
                                : null
                            }
                        </td>,
                    "brand":
                        <td>{order.brand}</td>,
                    "items":
                        <td style={{ width: "35%" }}>
                            <ul style={{ paddingLeft: "1rem", listStyleType: "square" }}>
                                {order.items.map((item) => (
                                    <li key={item._id}>
                                        <Badge className="me-1" bg="info">{item.quantity}</Badge>
                                        {item.quantityRefunded ? <Badge className="me-1" style={{ marginLeft: "2px" }} bg="danger">-{item.quantityRefunded}</Badge> : null}
                                        {item.name} {(item.variant && item.variant.sku && item.variant.sku.length >= 4) 
                                            ? <Badge className="small" bg="light" text="dark">{item.variant.sku.substr(0,4)}</Badge>
                                            : null
                                            }
                                        
                                        {item.quantityPacked || item.quantityOutOfStock || item.quantityDamaged
                                            ? <OrderItemPackingStatus
                                                item={item}
                                            />
                                            : null}
                                    </li>
                                ))}
                            </ul>
                        </td>,
                    "totalAmount":
                        <td>
                            <NumericFormat displayType={'text'} decimalSeparator="," value={order.totalAmount} thousandSeparator="." prefix={'$'} />
                        </td>,
                    "invoiceSentAt":
                        <td className="text-center" style={{ width: "6rem", wordWrap: "anywhere" }}>
                            {order.invoiceSentAt && order.invoiceSentAt !== "-"
                                ? <div>
                                    SI
                                    <div className="pt-2">
                                        <Receipt
                                            orderId={order._id}
                                        />
                                    </div>
                                </div>
                                : "NO"}
                        </td>,
                    "creditNoteCreatedAt":
                        <td className="text-center">
                            {order.creditNoteCreatedAt
                                ? <div>
                                    {(new Date(order.creditNoteCreatedAt)).toLocaleString('es-CL')}<br />
                                    <Badge bg="info">{order.creditNoteNumber}</Badge><br />
                                    <div className="pt-2">
                                        <CreditNote
                                            orderId={order._id}
                                        />
                                    </div>
                                </div>
                                : "NO"}
                        </td>,
                }[field]
            }
        </Fragment>
    );
}

export default OrderField;