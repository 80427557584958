export const dateFilterSchema = {
    dateRangeOptions: [
        { id: "any", name: "Todo" },
        { id: "today", name: "Hoy" },
        { id: "last3Days", name: "Últimos 3 días" },
        { id: "last7Days", name: "Últimos 7 días" },
        { id: "last30Days", name: "Últimos 30 días" },
        { id: "custom", name: "Día específico" },
        { id: "range", name: "Rango personalizado" },
    ],
    date: {
        range: "any",
        from: null,
        until: null,
    },
};

export const defaultOrderFiltersSchema = {
    statuses: [
    ],
    shippingTypes: [
        { id: "bluex_normal", name: "Normal", value: true },
        { id: "bluex_priority", name: "Priority", value: true },
        { id: "boosmap_normal", name: "24Hrs", value: true },
        { id: "99minutes_normal", name: "99Min", value: true },
        { id: "cross_docking", name: "Colecta", value: true },
        { id: "self_service", name: "Flex", value: true },
        { id: "drop_off", name: "Envíos", value: true },
        { id: "xd_drop_off", name: "Agencias", value: true },
        { id: "fulfillment", name: "Full", value: true },
        { id: "ripley_sameday", name: "Ripley-sameday", value: true },
        { id: "falabella_sameday", name: "Falabella-sameday", value: true },
        { id: "paris_nextday", name: "Paris-nextday", value: true },
        { id: "punto_pickup", name: "Punto-pickup", value: true },
        { id: "other", name: "Otro", value: true },
    ],
    ...dateFilterSchema,
    brands: [
        { id: "16 Hrs", name: "16 Hrs", value: true },
        { id: "Pluma", name: "Pluma", value: true },
        { id: "Panama Jack", name: "Panama Jack", value: true },
        { id: "Zappa", name: "Zappa", value: true },
        { id: "Bruno Rossi", name: "Bruno Rossi", value: true },
        { id: "Pollini", name: "Pollini", value: true },
        { id: "Mingo", name: "Mingo", value: true },
        { id: "Luz Da Lua", name: "Luz Da Lua", value: true },
        { id: "Dakota", name: "Dakota", value: true },
        { id: "PJack", name: "PJack", value: true },
        { id: "IBiza's heritage", name: "IBiza's heritage", value: true },
        { id: "Stanley", name: "Stanley", value: true },
        { id: "Gioseppo", name: "Gioseppo", value: true },
        { id: "AS98", name: "AS98", value: true },
        { id: "Powerfull", name: "Powerfull", value: true },
        { id: "Caletines bacanes", name: "Caletines bacanes", value: true },
        { id: "Gino", name: "Gino", value: true },
        { id: "Sherpa's", name: "Sherpa's", value: true },
        { id: "Otra", name: "Otra", value: true },
        { id: "Mixto", name: "Mixto", value: true },
    ],
    shopGroups: [
        { id: "PZ", name: "PZ", value: true },
        { id: "MercadoLibre", name: "MercadoLibre", value: false },
        { id: "Ripley", name: "Ripley", value: false },
        { id: "paris.cl", name: "Paris", value: false },
        { id: "Dafiti", name: "Dafiti", value: false },
        { id: "FalabellaGlobalProduction", name: "Falabella", value: false },
        //{ id: "Longtail", name: "Tiendas", value: false },
    ],
    groups: ["statuses", "shippingTypes", "brands", "shopGroups"],
};

export const dateLimitedFilterSchema = {
    dateRangeOptions: [
        { id: "last30Days", name: "Últimos 30 días" },
        { id: "last7Days", name: "Últimos 7 días" },
        { id: "last3Days", name: "Últimos 3 días" },
        { id: "today", name: "Hoy" },
        { id: "custom", name: "Día específico" },
        { id: "range", name: "Rango personalizado" },
    ],
    date: {
        range: "today",
        from: null,
        until: null,
    },
};

export const creditNoteDateLimitedFilterSchema = {
    creditNoteDateRangeOptions: [
        { id: "none", name: "Sin nota de crédito" },
        { id: "last30Days", name: "Últimos 30 días" },
        { id: "last7Days", name: "Últimos 7 días" },
        { id: "last3Days", name: "Últimos 3 días" },
        { id: "today", name: "Hoy" },
        { id: "custom", name: "Día específico" },
        { id: "range", name: "Rango personalizado" },
    ],
    creditNoteDate: {
        range: "none",
        from: null,
        until: null,
    },
};

export const dateLimitedOrderFiltersSchema = {
    ...defaultOrderFiltersSchema,
    ...dateLimitedFilterSchema,
};

export const getDefaultOrderFiltersSchema = (salesShopGroup) => {
    return {
        ...defaultOrderFiltersSchema,
        shopGroups: defaultOrderFiltersSchema.shopGroups.map((shopGroup) => {
            return {
                id: shopGroup.id,
                name: shopGroup.name,
                value: salesShopGroup === shopGroup.id,
            };
        }),
    };
};

export const getDateLimitedOrderFiltersSchema = (salesShopGroup) => {
    return {
        ...dateLimitedOrderFiltersSchema,
        shopGroups: defaultOrderFiltersSchema.shopGroups.map((shopGroup) => {
            return {
                id: shopGroup.id,
                name: shopGroup.name,
                value: salesShopGroup === shopGroup.id,
            };
        }),
    };
};

export const getShopGroupsSchema = (salesShopGroup) => {
    const shopGroupsSchema = [
        { id: "PZ", name: "PZ", value: true },
        { id: "MercadoLibre", name: "MercadoLibre", value: false },
        { id: "Ripley", name: "Ripley", value: false },
        { id: "paris.cl", name: "Paris", value: false },
        { id: "Dafiti", name: "Dafiti", value: false },
        { id: "FalabellaGlobalProduction", name: "Falabella", value: false },
        //{ id: "Longtail", name: "Tiendas", value: false },
    ];

    return {
        shopGroups: shopGroupsSchema.map((shopGroup) => {
            return {
                id: shopGroup.id,
                name: shopGroup.name,
                value: salesShopGroup === shopGroup.id,
            };
        }),
    };
};
