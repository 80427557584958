import React from 'react';
import Form from "react-bootstrap/Form";

import SubHeader from "../Layout/SubHeader";
import PrinterDetails from '../LabelPrinter/PrinterDetails';

const AutoPackingsSubHeader = ({ salesShopGroup, printer, readyToPrint, canPrint, printPackings, setPrintPackings }) => {
    return (  
        <SubHeader>
            <div
                className="printerInfo bg-light float-end ms-2 mt-1 mb-2 p-3 border"
            >
                {canPrint()
                ? printer
                    ? <PrinterDetails
                        printer={printer}
                        isAvailable={readyToPrint}
                    />
                    : <div className="w-100 text-center">Ninguna impresora seleccionada</div>
                : <div className="w-100 text-center">Impresión no disponible</div>
                }
            </div>
            
            <h2>
                PACKING DE VENTAS
            </h2>

            <Form className="mt-2">
                <Form.Check 
                    className=""
                    inline
                    name="autoPrint"
                    type="radio"
                    defaultChecked={!printPackings}
                    label="Sólo descarga"
                    value="false"
                    onChange={(event) => setPrintPackings(false)}
                />
                <Form.Check 
                    className="ms-3"
                    inline
                    name="autoPrint"
                    type="radio"
                    defaultChecked={printPackings}
                    label="Impresión automática"
                    value="true"
                    disabled={!canPrint()}
                    onChange={(event) => setPrintPackings(true)}
                />
            </Form>
        </SubHeader>
    );
};
 
export default AutoPackingsSubHeader;