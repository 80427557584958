import React, { useContext } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import LoadingSpinner from "../Layout/LoadingSpinner";
import InfoModalContent from '../Layout/InfoModalContent';
import SelectPrinterModalHeader from './SelectPrinterModalHeader';

import { PrinterContext } from '../../context/PrinterContext';
import PrinterDetails from './PrinterDetails';

const SelectPrinterModal = ({ show, setShow }) => {
    const { 
        printerRefreshing, printingEnabled, printer, availablePrinters,
        selectPrinter, refreshPrinters 
    } = useContext(PrinterContext);

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={() => setShow(false)}
            className="printerModal"
        >
            <SelectPrinterModalHeader 
                setShow={setShow}
                refreshPrinters={refreshPrinters}
            />
            <Modal.Body className="text-center">
                {printerRefreshing
                ? <div className="text-center">
                    <br />
                    <br />
                    <LoadingSpinner />
                </div>
                : printingEnabled 
                ? availablePrinters && availablePrinters.length > 0
                    ? <Form>
                        {availablePrinters.map((availablePrinter, index) => 
                        <Form.Group className="printerSelector" key={"availablePrinter_" + index} controlId={"availablePrinter_radio_" + index} >
                            <Form.Control 
                                className="btn-check" 
                                type="radio" 
                                checked={printer && availablePrinter.uid === printer.uid} 
                            />
                            <Form.Label className="w-100 btn btn-outline-primary p-4 text-start" onClick={() => selectPrinter(availablePrinter)}>
                                <PrinterDetails
                                    printer={availablePrinter}
                                    isAvailable={true}
                                />
                            </Form.Label>
                        </Form.Group>
                        )}
                    </Form>
                    : <InfoModalContent
                        msg="No hay impresoras disponibles."
                    />
                : <InfoModalContent
                    msg="La impresión de etiquetas no está disponible en este momento."
                />
                }
            </Modal.Body>
        </Modal>
    );
}

export default SelectPrinterModal;
