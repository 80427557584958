import React, { useContext } from 'react';
import { useNavigate  } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FaPlayCircle } from "react-icons/fa";

import { UserContext } from '../../context/UserContext';
import { SiteContext } from "../../context/SiteContext";

const Home = () => {
    const { user } = useContext(UserContext);
    const { setSalesShopGroup } = useContext(SiteContext);

    const navigate = useNavigate();

    const start = (shopGroup) => {
        setSalesShopGroup(shopGroup);
        navigate("/pickings");
    };

    return (
        <Container>
            <br />
            <br />
            <Row className="justify-content-end">
                <Col lg={6}>
                    <div className="jumboDesktop">
                        <h1>
                            <b>Bienvenido al
                            Backoffice de Gino</b>
                        </h1>
                        <br/>
                        <p className="lead">
                            Ingresaste al sistema como: <br/>
                            <em><strong>{user.email}</strong></em>
                        </p>
                        <br/>

                        <div className="homeMenu d-grid gap-1">                       
                            <Button
                                variant="light"
                                onClick={() => start("PZ")}
                            >
                                <FaPlayCircle className="me-2" style={{ marginBottom: "1px" }}/>
                                <span className="">VENTAS PZ</span>
                            </Button>
                        
                        
                            <Button
                                variant="light"
                                onClick={() => start("MercadoLibre")}
                            >
                                <FaPlayCircle className="me-2" style={{ marginBottom: "1px" }}/>
                                <span className="">VENTAS ML</span>
                            </Button>
                        
                            <Button
                                variant="light"
                                onClick={() => start("Ripley")}
                            >
                                <FaPlayCircle className="me-2" style={{ marginBottom: "1px" }}/>
                                <span className="">VENTAS RIPLEY</span>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;