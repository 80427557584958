import React, { Fragment, useCallback, useContext, useState } from "react";
import axios from "axios";

import OrderItemsModal from "./OrderItemsModal";
import AddOrderForm from "./AddOrderForm";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import { isBuffered, getExpectedPackingDate } from "../../helpers/Packing";
import { getShopGroupFromOrder } from "../../helpers/ShopGroups";

const AddOrder = ({
    orders,
    setOrders,
    setInfoModalData,
    handleOrderSelect,
    mode
}) => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [validated, setValidated] = useState(false);
    const [orderId, setOrderId] = useState("");

    const [orderLoading, setOrderLoading] = useState(false);
    const [packingOrder, setPackingOrder] = useState(null);

    const handleOrderPacked = useCallback(
        (order) => {
            // Cuando quedan en estado review ya no se agregan a la lista para etiquetas
            // pues se espera que se pistolee nuevamente una segunda vez
            if (order.status !== "review" || order.prevStatus === "review") {
                setOrders((oldOrders) => [...oldOrders, order]);
                handleOrderSelect(order._id, true);
            }

            setOrderId("");
            setValidated(false);
            setPackingOrder(null);
        },
        [handleOrderSelect, setOrders],
    );

    const handleOrderPacking = useCallback(
        (order) => {
            if (!["picking", "packing", "review"].includes(order.status)) {
                setInfoModalData({
                    type: "error",
                    size: "md",
                    msg:
                        "La venta seleccionada está en estado '" +
                        order.status +
                        "'. No se puede imprimir etiqueta de packing.",
                });

                return false;
            } else if (!order.items.reduce((x,y) => x + y.quantityToPack, 0)){
                setInfoModalData({
                    type: "error",
                    size: "md",
                    msg: "La venta no tiene ningún producto para packing.",
                });
                                
                return false;
            } else if (isBuffered(order)) {
                const packingDate = getExpectedPackingDate(order);
                setInfoModalData({
                    type: "error",
                    size: "md",
                    msg: "La venta no se puede procesar aún pues el shipping se encuentra BUFFERED. La fecha de liberación del shipping es el " + packingDate + ".",
                });
                                
                return false;
            } else if (getShopGroupFromOrder(order) !== salesShopGroup) {
                setInfoModalData({
                    type: "error",
                    size: "md",
                    msg: "La venta seleccionada es de un origen distinto a " + salesShopGroup + ", no se puede procesar.",
                });
                                
                return false;
            } else if (order.shippingType === "Otro") {
                setInfoModalData({
                    type: "error",
                    size: "md",
                    msg: "La venta seleccionada requiere de otro tipo de despacho.",
                });
                                
                return false;
            } else if (
                orders.length > 0 &&
                orders[0].shippingType &&
                orders[0].shippingType !== order.shippingType
            ) {
                setInfoModalData({
                    type: "error",
                    size: "md",
                    msg: "La venta seleccionada tiene un tipo de despacho distinto a la venta anterior, no se puede procesar.",
                });
                                
                return false;
            } else if (order.items.filter((item) => !item.variant).length && order.shop === "MercadoLibre") {
                setInfoModalData({
                    type: "alert",
                    size: "md",
                    msg: "La venta seleccionada tiene items sin información de SKU, se procesará directamente.",
                });

                handleOrderPacked(order);
                                
                return false;
            } else if (order.items.filter((item) => !item.variant).length) {
                setInfoModalData({
                    type: "alert",
                    size: "md",
                    msg: "La venta seleccionada tiene items sin información de SKU, no se puede procesar.",
                });
                                
                return false;
            } else if (order.status === "packing") {
                setInfoModalData({
                    type: "prompt",
                    size: "md",
                    msg: <span>
                        Esta venta ya está en estado packing.<br />
                        <br />
                        <strong>¿Seguro que quieres continuar?</strong>
                    </span>,
                    ok: () => {setPackingOrder(order); setInfoModalData({})},
                    cancel: () => setInfoModalData({}),
                });
                                
                return false;
            } else {
                setPackingOrder(order);
                return true;
            }
        },
        [handleOrderPacked, orders, salesShopGroup, setInfoModalData],
    );

    const handleOrderLoaded = useCallback(
        (order) => {
            if (mode === "auto" && order.shippingLabel) {
                setInfoModalData({
                    type: "prompt",
                    size:"md",
                    msg: <span>
                        Ya se generaron etiquetas de despacho para la venta seleccionada.<br />
                        <br />
                        <strong>¿Deseas utilizar la última etiqueta registrada?</strong>
                        </span>,
                    okButton: "OK",
                    cancelButton: "NO, DESEO NUEVAS ETIQUETAS",
                    cancel: () => {
                        order.shippingLabel = null;

                        const ok = handleOrderPacking(order);
                        if (ok) {
                            setInfoModalData({});
                        }
                    },
                    ok: () => {
                        setInfoModalData({});

                        setOrders((oldOrders) => [...oldOrders, order]);
                        handleOrderSelect(order._id, true);
        
                        setOrderId("");
                        setValidated(false);
                        setPackingOrder(null);
                        
                    },
                });
            }
            else {
                handleOrderPacking(order);
            }
        },
        [handleOrderPacking, handleOrderSelect, mode, setInfoModalData, setOrders]
    );

    const handleAddOrder = useCallback(
        (id) => {
            setOrderLoading(true);

            // Para no agregar más de una vez cada órden
            const filteredOrders = orders.filter((order) => order._id === id);
            if (filteredOrders && filteredOrders.length > 0) {
                setInfoModalData({
                    type: "error",
                    msg: "La venta ya había sido ingresada.",
                });

                setOrderId("");
                setValidated(false);
                setOrderLoading(false);
                return;
            }

            axios
                .get(env.webApiBaseUrl + "/orders/" + id, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                })
                .then((orderResponse) => {
                    const order = orderResponse.data;
                    if (!order || !order._id) {
                        setInfoModalData({
                            type: "error",
                            msg: "Error al recuperar la venta.",
                        });
                    } else {
                        if (mode === "auto") {
                            axios
                                .get(env.webApiBaseUrl + "/orders/" + id + "/shippingLabels/last/zpl", {
                                    headers: {
                                        "X-Requested-With": "XMLHttpRequest",
                                        "X-CSRF-Token": csrfToken,
                                        Authorization: "Bearer " + user.token,
                                    },
                                    withCredentials: true,
                                })
                                .then((labelResponse) => {
                                    order.shippingLabel = labelResponse.data;
                                    handleOrderLoaded(order);
                                })
                                .catch(e => {
                                    if (e && e.response && e.response.status && e.response.status === 404) {
                                        handleOrderLoaded(order);
                                    }
                                    else {
                                        console.error("Error al obtener etiquetas guardadas: ", e);

                                        setInfoModalData({
                                            type: "error",
                                            msg: "No pude consultar las etiquetas de la venta.",
                                        });
                                    }
                                });
                        }
                        else {
                            handleOrderLoaded(order);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setInfoModalData({
                        type: "error",
                        msg: "No pude recuperar la venta. Verifica que el código ingresado sea válido.",
                    });
                })
                .finally(() => {
                    setOrderLoading(false);
                });
        },
        [csrfToken, env.webApiBaseUrl, handleOrderLoaded, mode, orders, setInfoModalData, user.token]
    );

    const handleChange = useCallback(
        (event) => {
            setOrderId(event.target.value);

            // auto submit
            if (event.target.value.length === 24) {
                handleAddOrder(event.target.value);
            }
        },
        [handleAddOrder]
    );

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            setValidated(true);

            handleAddOrder(orderId);
        },
        [handleAddOrder, orderId]
    );

    return (
        <Fragment>
            {packingOrder ?
            <OrderItemsModal 
                packingOrder={packingOrder}
                setPackingOrder={setPackingOrder}
                handleOrderPacked={handleOrderPacked}
            />
            : null
            }
            
            <AddOrderForm
                orderLoading={orderLoading}
                orderId={orderId}
                validated={validated}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                mode={mode}
            />
        </Fragment>
    );
};

export default AddOrder;
