import React, { memo } from 'react';
import Form from 'react-bootstrap/Form';

import OrderField from './OrderField';

const OrderRow = memo(({ order, selected, handleOrderSelect, fields, customActionsFunction, setInfoModalData }) => {
    //console.log("Order: ", order);

    return (
        <tr>
            {/*console.log("Rendering orderRow ", order._id)*/}
            {handleOrderSelect
                ? <td>
                    <Form.Check
                        id={"item_" + order._id}
                        type="checkbox"
                        checked={selected}
                        onChange={(event) => handleOrderSelect(order._id, event.target.checked)}
                    />
                </td>
                : null
            }

            {customActionsFunction
            ? <td className="text-center">
                {customActionsFunction(order)}
            </td>
            : null}

            {fields.filter((field) => field.id !== "customActionsFunction").map((field) => (
                <OrderField
                    key={order._id + "-field-" + field.id}
                    order={order}
                    field={field.id}
                    setInfoModalData={setInfoModalData}
                />
            ))}
        </tr>
    );
}, (prevProps, nextProps) => (
    prevProps.order === nextProps.order
    && prevProps.selected === nextProps.selected
));

export default OrderRow;