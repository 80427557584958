import React, { useContext, useState, useCallback } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Container from "react-bootstrap/Container";
import { Buffer } from "buffer";

import AutoPackingsSubHeader from "./AutoPackingsSubHeader";
import InfoModal from "../Layout/InfoModal";
import ErrorWithTrace from '../Layout/ErrorWithTrace';
import AddOrder from "./AddOrder";
import OriginForm from "./OriginForm";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";
import { PrinterContext } from '../../context/PrinterContext';

import { getFileExtensionFromContentType } from "../../helpers/File";

const AutoPackings = () => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);
    const { printer, canPrint, printZpl } = useContext(PrinterContext);

    const [printPackings, setPrintPackings] = useState(canPrint());

    const [printingPacking, setPrintingPacking] = useState(false);

    const [infoModalData, setInfoModalData] = useState(null);

    const hideInfoModal = useCallback(
        () => {
            setInfoModalData(infoModalData => ({
                ...infoModalData,
                show: false,
            }));
        }, []
    );

    const showInfoModal = useCallback(
        (infoModalData) => {
            setInfoModalData({
                ...infoModalData,
                show: true
            });
        }, []
    );

    const handleGeneratePackings = useCallback(
        async (order) => {
            setPrintingPacking(true);

            try {
                showInfoModal({
                    type: "loading",
                    size: "md",
                    msg: "Generando etiquetas",
                    closeButton: false,
                });

                const orderId = order._id;

                const packingsResponse = await axios.post(
                    env.webApiBaseUrl + "/orders/" + orderId + "/shippingLabel",
                    null,
                    {
                        headers: {
                            "X-Requested-With": "XMLHttpRequest",
                            "X-CSRF-Token": csrfToken,
                            Authorization: "Bearer " + user.token,
                        },
                        withCredentials: true,
                        timeout: 60000,
                    }
                );

                order.shippingLabel = packingsResponse.data;

                hideInfoModal();
            } catch (e) {
                console.error(e);

                const msg = e.response && e.response.data && e.response.data.msg 
                    ? e.response.data.msg
                    : e.message;

                showInfoModal({
                    type: "error",
                    size: "lg",
                    msg: <ErrorWithTrace 
                        msg="Problemas al generar las etiquetas:"
                        traceList={[msg]}
                    />
                });
            } finally {
                setPrintingPacking(false);
            }
        },
        [csrfToken, env.webApiBaseUrl, hideInfoModal, showInfoModal, user.token]
    );

    const downloadLabelAsFile = useCallback(
        (contentType, contentBase64) => {
            const fileBuff = Buffer.from(contentBase64, "base64");
            const fileExtension = getFileExtensionFromContentType(contentType);
            const fileName = "packings-" + Date.now() + '.' + fileExtension;
            fileDownload(fileBuff, fileName, contentType);
        }, []
    );

    const handlePrintPackings = useCallback(
        async (order) => {
            setPrintingPacking(true);

            let contentType;
            let contentBase64;
            try {
                //console.log("Order: ", order);

                if (!order.shippingLabel) {
                    console.log("Se solicita generar etiquetas...");
                    await handleGeneratePackings(order);
                }

                if (order.shippingLabel) {
                    showInfoModal({
                        type: "loading",
                        size: "md",
                        msg: printPackings ? "Imprimiendo etiquetas" : "Descargando etiquetas",
                        closeButton: false,
                    });
    
                    contentType = order.shippingLabel.contentType;
                    contentBase64 = order.shippingLabel.contentBase64;
    
                    if (!printPackings) {
                        downloadLabelAsFile(contentType, contentBase64);
                        hideInfoModal();
                    }
                    else if (!canPrint()) {
                        showInfoModal({
                            type: "error",
                            size: "md",
                            msg: "La impresión no está disponible en este momento. Se descargará archivo con etiquetas.",
                        });

                        downloadLabelAsFile(contentType, contentBase64);
                    }
                    else if (contentType !== "text/plain") {
                        showInfoModal({
                            type: "error",
                            size: "md",
                            msg: <span>
                                Formato de etiquetas no permite impresión directa: <strong>{ contentType }</strong>.<br />
                                Se descargará archivo con etiquetas.
                            </span>,
                        });

                        downloadLabelAsFile(contentType, contentBase64);
                    }
                    else {
                        console.log("Imprimiendo etiquetas...");
                        
                        const fileBuff = Buffer.from(contentBase64, "base64");
                        await printZpl(order.origin, order.shop, order.shippingType, fileBuff.toString());

                        hideInfoModal();
                    }
                }
            } catch (e) {
                console.error(e);

                const msg = e.message;
                showInfoModal({
                    type: "error",
                    size: "lg",
                    msg: <ErrorWithTrace 
                        msg="Problemas al imprimir las etiquetas:"
                        traceList={[msg]}
                    />
                });

                if (contentType && contentBase64) {
                    downloadLabelAsFile(contentType, contentBase64);
                }
            } finally {
                setPrintingPacking(false);
            }
        },
        [canPrint, downloadLabelAsFile, handleGeneratePackings, hideInfoModal, printPackings, printZpl, showInfoModal]
    );

    const handleOrderSelect = useCallback(
        (orderId, checked) => {
            // Esto ya no se usa, pues ahora se trabaja de a sólo una venta
            //console.log("Cambia selección item ", orderId, " checked ", checked);
        }, []
    );

    const setPrintOrder = useCallback(
        (ordersFunc) => {
            const orders = ordersFunc([]);
            handlePrintPackings(orders[0]);
        }, [handlePrintPackings]
    );

    return (
        <div>
            <AutoPackingsSubHeader
                salesShopGroup={salesShopGroup}
                printer={printer}
                canPrint={canPrint}
                printingPacking={printingPacking}
                printPackings={printPackings}
                setPrintPackings={setPrintPackings}
                readyToPrint={true}
            />

            <br />

            <Container fluid>
                {infoModalData ? (
                    <InfoModal
                        closeButton={
                            infoModalData.closeButton !== undefined
                                ? infoModalData.closeButton
                                : true
                        }
                        type={infoModalData.type}
                        size={infoModalData.size}
                        msg={infoModalData.msg}
                        ok={infoModalData.ok}
                        cancel={infoModalData.cancel}
                        show={infoModalData.show}
                        close={hideInfoModal}
                        retry={hideInfoModal}
                        okButton={infoModalData.okButton}
                        cancelButton={infoModalData.cancelButton}
                    />
                ) : null}

                <Container fluid>
                    <OriginForm />
                </Container>

                <br />

                <AddOrder
                    mode="auto"
                    orders={[]}
                    setOrders={setPrintOrder}
                    setInfoModalData={setInfoModalData}
                    handleOrderSelect={handleOrderSelect}
                />
            </Container>
        </div>
    );
};

export default AutoPackings;
