import React, { useCallback, useContext, useState } from 'react';
import axios from "axios";
import fileDownload from "js-file-download";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FaRegListAlt } from 'react-icons/fa';

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

const Picking = ({orderId}) => {
    const { env, csrfToken } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [pickingLoading, setPickingLoading] = useState(false);

    const getPicking = useCallback(
        () => {
            setPickingLoading(true);

            const pickingsRequest = {
                selectedOrders: [orderId],
                bypassStatusChange: true,
            };

            console.log(
                "Printing picking tickets for: ",
                pickingsRequest.selectedOrders
            );


            axios.post(env.webApiBaseUrl + "/orders/pickings", pickingsRequest, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
                responseType: "blob",
                timeout: 60000,
            })
            .then((pickingsResponse) => {
                const filename = "pickings-" + Date.now() + ".pdf";
                fileDownload(pickingsResponse.data, filename);
            })
            .catch((e) => {
                console.error("Problemas al generar picking: ", e);
            })
            .finally(() => {
                setPickingLoading(false);
            });
        }, 
        [csrfToken, env.webApiBaseUrl, orderId, user.token]
    );

    return (
        <Button size="sm" variant="primary" onClick={getPicking}>
        {pickingLoading
        ? <Spinner animation="border" role="status" size="sm"/>
        : <FaRegListAlt className="lead"/>
        }
    </Button>
    );
}
 
export default Picking;