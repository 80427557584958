import React from 'react';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const ColumnHeader = ({ id, name, sortConfig, requestSort }) => {
    //console.log("sortConfig: ", sortConfig);

    return (
        <th className="dataTable" onClick={ () => requestSort(id) }>
            <div className="float-end">
                {sortConfig.key === id
                ? (
                    sortConfig.direction === "ascending"
                    ? <FaArrowDown />
                    : <FaArrowUp />
                )
                : null
                }
            </div>

            <div className="pe-4">
                {name}
            </div>
        </th>
    );
}
 
export default ColumnHeader;