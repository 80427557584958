import React, { createContext, useCallback, useEffect, useState, useMemo } from 'react';
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

import { processZplForPrinting } from '../helpers/Zpl';

export const PrinterContext = createContext();

const getPrinterDataFromDevice = (zebraPrinter) => {
    return {
        "name": zebraPrinter.name,
        "connection": zebraPrinter.connection,
        "uid": zebraPrinter.uid,
        "provider": zebraPrinter.provider,
        "manufacturer": zebraPrinter.manufacturer,
    };
};

const getTextFromStatusMessage = (printerStatus) => {
    let result = printerStatus;

    result = result.replace(/Paper out/g, "Sin papel");
    result = result.replace(/Ribbon Out/g, "Sin cinta");
    result = result.replace(/Media Door Open/g, "Cabezal de impresión abierto");
    result = result.replace(/Printer Paused/g, "En pausa");
    result = result.replace(/Error: Unknown Error/g, "Sin conexión");

    return result;
};

/*const getTextFromStatusMessage = (printerStatus) => {
    switch (printerStatus) {
        case "Ready":
            return "Lista";
        case "Offline":
            return "Sin conexión";
        case "Paper Out":
            return "Sin papel";
        case "Head Open":
            return "Cabezal de impresión abierto";
        case "Ribbon Out":
            return "Sin cinta";
        case "Paused":
            return "En pausa";

        default:
            return printerStatus;
    }
};*/


/*const printRawZebraPrinter = (controller, zplToPrint) => {
    return new Promise((resolve, reject) => {
        console.log("Starting printRaw...");

        const printerController = new window.Zebra.Printer(controller);

        printerController.isPrinterReady(
            (status) => {
                console.log("Status before send: ", status);

                printerController.send(zplToPrint, 
                    () => {
                        console.log("Zpl sent to device");

                        printerController.isPrinterReady(
                            (status) => {
                                console.log("Status after send: ", status);
                                resolve(status);
                            },
                            (e) => {
                                console.log("Printer status after sending data: ", e);
                                reject(new Error("La impresora tuvo problemas al imprimir: " + getTextFromStatusMessage(e)));
                            });
                    }, 
                    (e) => {
                        console.error("Error al imprimir:", e);
                        reject(new Error("Problemas al enviar etiqueta a impresión: " + e.getMessage()));
                    });
            },
            (e) => {
                console.log("Printer not ready: ", e);
                reject(new Error("La impresora tuvo problemas al imprimir: " + getTextFromStatusMessage(e)));
            });
    });
};*/

const PrinterProvider = (props) => {  
    const [ printingEnabled, setPrintingEnabled ] = useState(false);
    const [ printerRefreshing, setPrinterRefreshing ] = useState(false);
    const [ printerWorking, setPrinterWorking ] = useState(false);
    const [ availablePrinters, setAvailablePrinters ] = useState([]);
    const [ printer, setPrinter ] = useState(() => {
        const saved = localStorage.getItem("printer");
        return saved ? JSON.parse(saved) : null;
    });

    const [ availableDevices, setAvailableDevices ] = useState([]);
    //const [ printerController, setPrinterController ] = useState(null);

    const browserPrint =  useMemo(() => new ZebraBrowserPrintWrapper(), []);

    const refreshPrinters = useCallback(async () => {
        setPrinterRefreshing(true);

        try {
            // Búsqueda de impresoras disponibles
            const deviceList = await browserPrint.getAvailablePrinters();
            //console.log("Device list:", deviceList);

            setPrintingEnabled(true);

            if (deviceList instanceof Array) {
                setAvailableDevices(deviceList);
                setAvailablePrinters(deviceList.map(p => getPrinterDataFromDevice(p)));
            }
        }
        catch(e) {
            setPrintingEnabled(false);
            console.error("No pude obtener las impresoras", e);
        }
        finally {
            setPrinterRefreshing(false);
        }
    }, [browserPrint]);

    const canPrint = useCallback(() => {
        return printingEnabled && printer && browserPrint.getPrinter() && true;
    }, [printingEnabled, printer, browserPrint]);

    const printZpl = useCallback(
        async (origin, shop, shippingType, zplData) => {
            try {
                setPrinterWorking(true);

                if (!browserPrint.getPrinter()) {
                    throw new Error("El controlador de impresora no está disponible.");
                }

                const zplToPrint = processZplForPrinting(origin, shop, shippingType, zplData);
                //console.log("Zpl to print: ", zplToPrint);

                const status = await browserPrint.checkPrinterStatus();
                if (status.isReadyToPrint) {
                    browserPrint.print(zplToPrint);
                    
                    /*const statusAfter = await browserPrint.checkPrinterStatus();
                    if (!statusAfter.isReadyToPrint) {
                        console.error("Error/s after", status);
                        throw new Error("La impresora tuvo problemas al imprimir: " + getTextFromStatusMessage(status.errors) + ".");
                    }*/
                } 
                else {
                    console.error("Error/s before", status);
                    throw new Error("La impresora no está preparada para imprimir: " + getTextFromStatusMessage(status.errors) + ".");
                }
            }
            catch(e) {
                console.error("Problemas al imprimir: ", e);
                throw e;
            }
            finally {
                setPrinterWorking(false);
            }
        }, [browserPrint]
    );

    const isReadyToPrint = useCallback(
        async () => {
            try {
                if (!browserPrint.getPrinter()) {
                    return false;
                }

                const status = await browserPrint.checkPrinterStatus();
                if (status.isReadyToPrint) {
                    return true;
                } 
                else {
                    return false;
                }
            }
            catch(e) {
                console.error("Problemas revisar estado de impresora: ", e);
            }

            return false;
        }, [browserPrint]
    );

    const selectPrinter = useCallback((printer) => {
        setPrinter({
            "name": printer.name,
            "connection": printer.connection,
            "uid": printer.uid,
            "provider": printer.provider,
            "manufacturer": printer.manufacturer,
        });
    }, []);

    const setPrinterController = useCallback((printer) => {
        browserPrint.setPrinter(printer);
    }, [browserPrint]);

    // Carga el controlador para la impresora seleccionada
    useEffect(() => {
        const setupPrinting = () => {
            if (browserPrint) {
                if (printer && availableDevices && availableDevices.length > 0) {
                    const device = availableDevices.find(p => p.uid === printer.uid);
                    const controller = device ? device : null;
                    setPrinterController(controller);
                    console.log("Loaded printer controller: ", controller);
                }
            }
        }

        setupPrinting();

        return () => {
        }
    }, [availableDevices, browserPrint, printer, setPrinterController]);

    // Guarda impresora elegida en localStorage
    useEffect(() => {
        if (printer) {
            localStorage.setItem("printer", JSON.stringify(printer));
        }
    }, [printer]);

    // Obtiene la lista de impresoras al cargar la página
    useEffect(() => {
        refreshPrinters();
    }, [refreshPrinters]);

    // Selecciona la impresora por defecto por omisión
    useEffect(() => {
        const setupPrintingDefaults = async () => {
            try {
                // Si no hay impresora elegida, establezco la por defecto en browserPrint
                if (browserPrint && !printer) {
                    const device = await browserPrint.getDefaultPrinter();
                    if (device && device.uid) {
                        setPrinter(getPrinterDataFromDevice(device));
                        setPrinterController(device);
                    }
                }
            }
            catch(e) {
                console.error("No pude seleccionar la impresora por defecto: ", e);
            }
        }

        setupPrintingDefaults();
    }, [browserPrint, printer, setPrinterController]);

    return (
        <PrinterContext.Provider
            value={{
                printingEnabled,
                printerRefreshing,
                availablePrinters,
                printer,
                selectPrinter,
                canPrint,
                printerWorking,
                printZpl,
                refreshPrinters,
                isReadyToPrint,
            }}
        >
            {props.children}
        </PrinterContext.Provider>
    )
}

export default PrinterProvider;
