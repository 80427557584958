import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Container from "react-bootstrap/Container";
import { Buffer } from "buffer";

import ManualPackingsSubHeader from "./ManualPackingsSubHeader";
import AddOrder from "./AddOrder";
import OrdersTable from "../Orders/OrdersTable";
import InfoModal from "../Layout/InfoModal";
import ErrorWithTrace from '../Layout/ErrorWithTrace';

import useSortableData from "../../hooks/SortableData";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import { sortSeletedOrdersFromSortedOrders } from "../../helpers/SortableOrders";
import { chunks } from "../../helpers/Query";

const ManualPackings = () => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [orders, setOrders] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [printingPacking, setPrintingPacking] = useState(false);

    const [infoModalData, setInfoModalData] = useState(null);
    const [itemQuantity, setItemQuantity] = useState(0);

    const { items, requestSort, sortConfig } = useSortableData(orders, {
        direction: "ascending",
        key: "createdAt",
    });

    const handleOrderSelect = useCallback(
        (orderId, checked) => {
            //console.log("Cambia item ", orderId, " checked ", checked);
    
            if (checked) {
                setSelectedOrders((oldSelectedOrders) => {
                    if (oldSelectedOrders.includes(orderId)) {
                        return oldSelectedOrders;
                    } else {
                        return [...oldSelectedOrders, orderId];
                    }
                });
            } else {
                setSelectedOrders((oldSelectedOrders) =>
                    oldSelectedOrders.filter((id) => id !== orderId)
                );
            }
        },
        [],
    );

    const clearOrders = useCallback(
        () => {
            setOrders([]);
            setSelectedOrders([]);
        },
        [],
    );

    const handlePrintPackingsForChunk = useCallback(
        async (selectedOrdersChunk) => {
            const packingsRequest = {
                selectedOrders: selectedOrdersChunk,
            };

            console.log(
                "Printing packing tickets for: ",
                packingsRequest.selectedOrders
            );

            try {
                const packingsResponse = await axios.post(
                    env.webApiBaseUrl + "/orders/packings",
                    packingsRequest,
                    {
                        headers: {
                            "X-Requested-With": "XMLHttpRequest",
                            "X-CSRF-Token": csrfToken,
                            Authorization: "Bearer " + user.token,
                        },
                        withCredentials: true,
                        timeout: 60000,
                    }
                );

                const contentType = packingsResponse.data.contentType;
                const contentBase64 = packingsResponse.data.contentBase64;
                if (contentBase64) {
                    const fileBuff = Buffer.from(contentBase64, "base64");

                    const fileExtension = contentType === "application/pdf" ? 'pdf' : 'txt';
                    const fileName = "packings-" + Date.now() + '.' + fileExtension;
                    fileDownload(fileBuff, fileName, contentType);
                }

                const processedOrdersInChunk = packingsResponse.data.processedOrderIdList;
                const errorsInChunk = packingsResponse.data.errors;

                setSelectedOrders((oldSelectedOrders) =>
                    oldSelectedOrders.filter(
                        (order) => !processedOrdersInChunk.includes(order)
                    )
                );

                return {
                    processedOrdersInChunk,
                    errorsInChunk,
                };
            } catch (e) {
                console.error(
                    "Problemas al generar las etiquetas para el grupo: ",
                    e
                );
                throw e;
            }
        },
        [csrfToken, env.webApiBaseUrl, user.token]
    );

    const handlePrintPackings = useCallback(
        async () => {
            setPrintingPacking(true);

            const processedOrders = [];
            const errors = [];
            try {
                const selectedOrdersChunks = chunks(
                    sortSeletedOrdersFromSortedOrders(selectedOrders, items),
                    env.downloadMaxChunkSize
                );

                let chunkNumber = 1;
                for (const selectedOrdersChunk of selectedOrdersChunks) {
                    setInfoModalData({
                        type: "loading",
                        msg:
                            "Generando las etiquetas para el grupo " +
                            chunkNumber +
                            " de " +
                            selectedOrdersChunks.length,
                        closeButton: false,
                    });

                    const chunkData = await handlePrintPackingsForChunk(selectedOrdersChunk);
                    processedOrders.push(...chunkData.processedOrdersInChunk);
                    errors.push(...chunkData.errorsInChunk);

                    chunkNumber++;
                }

                //setOrders([]);
                console.log("Processed orders: ", processedOrders);
                setOrders((oldOrders) =>
                    oldOrders.filter(
                        (order) => !processedOrders.includes(order._id)
                    )
                );

                setInfoModalData(null);
                if (errors.length) {
                    setInfoModalData({
                        type: "error",
                        size: "lg",
                        msg: <ErrorWithTrace 
                            msg="Problemas al generar las etiquetas:"
                            traceList={errors}
                        />
                    });
                }
            } catch (e) {
                console.error(e);

                setInfoModalData({
                    type: "error",
                    msg: "Problemas al generar las etiquetas.",
                });
            } finally {
                setPrintingPacking(false);
            }
        },
        [env.downloadMaxChunkSize, handlePrintPackingsForChunk, items, selectedOrders]
    );

    useEffect(() => {
        setItemQuantity(orders.reduce((a,b) => a + b.items.reduce((x,y) => x + y.quantity, 0), 0));
    }, [orders]);

    return (
        <div>
            <ManualPackingsSubHeader
                salesShopGroup={salesShopGroup}
                orderQuantity={orders.length}
                itemQuantity={itemQuantity}
                selectedOrderQuantity={selectedOrders.length}
                printingPacking={printingPacking}
                clearOrders={clearOrders}
                handlePrintPackings={handlePrintPackings}
            />

            <Container fluid>
                {infoModalData ? (
                    <InfoModal
                        closeButton={
                            infoModalData.closeButton !== undefined
                                ? infoModalData.closeButton
                                : true
                        }
                        type={infoModalData.type}
                        size={infoModalData.size}
                        msg={infoModalData.msg}
                        setMsg={setInfoModalData}
                        ok={infoModalData.ok}
                        cancel={infoModalData.cancel}
                    />
                ) : null}

                <br />
                <AddOrder
                    orders={orders}
                    setOrders={setOrders}
                    setInfoModalData={setInfoModalData}
                    handleOrderSelect={handleOrderSelect}
                />

                <br />

                <OrdersTable
                    orders={items}
                    setOrders={setOrders}
                    sortConfig={sortConfig}
                    requestSort={requestSort}
                    selectedOrders={selectedOrders}
                    handleOrderSelect={handleOrderSelect}
                    setInfoModalData={setInfoModalData}
                />
            </Container>
        </div>
    );
};

export default ManualPackings;
