import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { FaSync, FaBan } from 'react-icons/fa';

const SelectPrinterModalHeader = ({ setShow, refreshPrinters }) => {
    return (
        <Modal.Header>
            <Modal.Title className="flex-grow-1">
                Selección de impresora de etiquetas
            </Modal.Title>
            <div>
                <Button
                    variant="danger"
                    className="float-end ms-2 mt-1 mb-2"
                    onClick={() => setShow(false)}
                >
                    <FaBan style={{ marginBottom: "3px" }} />
                </Button>
                <Button
                    className="float-end ms-2 mt-1 mb-2"
                    onClick={() => refreshPrinters()}
                >
                    <FaSync style={{ marginBottom: "3px" }} />
                </Button>
            </div>
        </Modal.Header>
    );
}

export default SelectPrinterModalHeader;
