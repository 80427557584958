import React, { useEffect, createContext, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const UserContext = createContext();

const UserProvider = (props) => {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);

    const revokeSession = useCallback(() => {
        setUser(null);
        navigate("/");
    }, [navigate]);

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                revokeSession();
            }
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const alertUser = (e) => {
            e.preventDefault();
            e.returnValue = "";
        };

        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                revokeSession,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export default UserProvider;
