import React, { useContext, useState } from 'react';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from "react-bootstrap/Spinner";
import { FaPrint } from "react-icons/fa";

import { PrinterContext } from '../../context/PrinterContext';
import SelectPrinterModal from '../LabelPrinter/SelectPrinterModal';

const SelectPrinterButton = () => {
    const { printerWorking, printingEnabled, printer } = useContext(PrinterContext);

    const [ showSelectPrinter, setShowSelectPrinter ] = useState(false);

    return (
        <div
            className="ms-2 text-center"
        >
            <SelectPrinterModal
                show={showSelectPrinter}
                setShow={setShowSelectPrinter}
            />

            <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                    <Tooltip id="tooltipPrinter" className="text-center">
                        Impresora de etiquetas:<br/>
                        {printer && printer.name ? printer.name : "Ninguna"}
                    </Tooltip>
                }
            >
                <Button
                    className={ printingEnabled ? "headerButton" : "text-white" }
                    disabled={!printingEnabled}
                    variant="light"
                    onClick={() => setShowSelectPrinter(true)}
                >
                    { false //printerRefreshing
                    ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{marginLeft:"1px", marginRight:"2px"}}
                    />
                    : printerWorking 
                        ? <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{marginLeft:"1px", marginRight:"2px"}}
                        />
                        : <FaPrint style={{ fontSize: "1.2rem" }} /> 
                    }
                </Button>
            </OverlayTrigger>
        </div>
    );
}

export default SelectPrinterButton;