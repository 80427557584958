import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import InfoModal from "../Layout/InfoModal";
import ResyncPsOrder from "./ResyncPsOrder";
import ResyncCentryOrder from "./ResyncCentryOrder";
import ViewOrder from "./ViewOrder";
import CheckBrowserPrint from "./CheckBrowserPrint";
import SubHeader from "../Layout/SubHeader";

const Tools = () => {
    const [infoModalData, setInfoModalData] = useState(null);

    return (
        <div>
            <SubHeader>
                <h2>
                    HERRAMIENTAS
                </h2>
            </SubHeader>
            <br />

            <Container fluid>
                {infoModalData && infoModalData.msg
                ? (
                    <InfoModal
                        closeButton={true}
                        type={infoModalData.type}
                        size={infoModalData.size}
                        msg={infoModalData.msg}
                        setMsg={setInfoModalData}
                    />
                ) 
                : null
                }

                <Row>
                    <Col>
                        <ResyncPsOrder
                            setInfoModalData={setInfoModalData}
                        />
                    </Col>
                    <Col>
                        <ResyncCentryOrder
                            setInfoModalData={setInfoModalData}
                        />
                    </Col>
                </Row>
                <br />

                <ViewOrder
                    setInfoModalData={setInfoModalData}
                />

                <br />

                <CheckBrowserPrint
                    setInfoModalData={setInfoModalData}
                />

                <br />
            </Container>
        </div>
    );
}
 
export default Tools;