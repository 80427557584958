import React, { useContext, useCallback, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaSync } from "react-icons/fa";

import LoadingSpinner from "../Layout/LoadingSpinner";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

const ResyncCentryOrder = ({ setInfoModalData }) => {
    const shops = [
        { id: "MercadoLibre", name: "MercadoLibre" },
        { id: "Ripley", name: "Ripley" },
        { id: "paris.cl", name: "Paris" },
        { id: "Dafiti", name: "Dafiti" },
        { id: "FalabellaGlobalProduction", name: "Falabella" },
    ];

    const { env, csrfToken } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [formData, setFormData] = useState({ shop: "MercadoLibre", idShop: null });
    const [orderLoading, setOrderLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleChange = useCallback((field, value) => {
        setFormData((oldData) => ({
            ...oldData,
            [field]: value,
        }));
    }, []);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        setValidated(true);
        setOrderLoading(false);

        axios
            .get(env.webApiBaseUrl + "/orders/syncCentryOrder/" + formData.shop + "/" + formData.idShop, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
            })
            .then((orderResponse) => {
                    setInfoModalData({
                        type: "success",
                        msg: "Venta sincronizada correctamente",
                        size: "sm",
                    });
                    setValidated(false);
            })
            .catch((error) => {
                console.log(error);
                setInfoModalData({
                    type: "error",
                    msg: "No pude sincronizar la venta. Verifica que el número ingresado sea válido.",
                    size: "sm",
                });
            })
            .finally(() => {
                setOrderLoading(false);
            });
    }, [
        csrfToken,
        env.webApiBaseUrl,
        formData.idShop,
        formData.shop,
        setInfoModalData,
        user.token,
    ]);

    return (
        <Container style={{ height: "8rem" }}>
            <div className="w-100 h-100 p-4 border">
                {orderLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Form
                        className="h-100"
                        validated={validated}
                        onSubmit={(event) => handleSubmit(event)}
                    >
                        <Row>
                            <Col>
                                <Form.Label>Sincronizar venta desde Centry</Form.Label>
                            </Col>
                        </Row>
                        <Row>

                            <Col sm={4}>
                                <Form.Select
                                    id="shop"
                                    className=""
                                    name="shop"
                                    onChange={(event) => handleChange("shop", event.target.value)}
                                >
                                    {shops.map((option) => (
                                        <option
                                            key={"option_" + option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={6}>
                                <Form.Group className="form-group">
                                    <Form.Control
                                        required
                                        name="idShop"
                                        type="text"
                                        placeholder="Número de venta"
                                        onChange={(event) =>
                                            handleChange("idShop", event.target.value)
                                        }
                                        maxLength={24}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Button className="w-100" type="submit" variant="primary">
                                    <FaSync />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </div>
        </Container>
    );
};

export default ResyncCentryOrder;
