import React, { Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { SwitchTransition, CSSTransition } from "react-transition-group";

import LoadingSpinner from './LoadingSpinner';
import Alert from './Alert';

import CheckImg from '../../assets/check.png';

const InfoModalContent = ({ type, closeButton, retryButton, okButton, cancelButton, msg, setMsg, ok, cancel, close, retry }) => {
    let icon;
    switch (type) {
        case 'loading':
            icon = (
                <LoadingSpinner />
            );
            break;
        case 'success':
            icon = <img src={CheckImg} width="100" height="100" alt="OK" />;
            break;
        default:
            icon = <Alert />;
    }

    const closeButtonText = retryButton ? 'REINTENTAR' : 'CERRAR';
    const closeButtonFunc = () => {
        if (closeButton && typeof(close) === "function") {
            close();
        }
        else if (retryButton && typeof(retry) === "function") {
            retry();
        }
        else if (typeof(setMsg) === "function") {
            setMsg({});
        }
    }

    return (
        <Fragment>
            <SwitchTransition>
                <CSSTransition key={type} classNames="wrk-alert" timeout={300}>
                    <div className="wrk-icon mt-4 mb-4">
                        {icon}
                    </div>
                </CSSTransition>
            </SwitchTransition>

            <SwitchTransition>
                <CSSTransition key={msg} classNames="wrk-alert" timeout={300}>
                <div>
                    {type === "prompt"
                    ? <Container>
                        <div>
                            {msg}
                        </div>
                        <div className="mt-2">
                            <Button 
                                variant="primary" 
                                className="w-100 my-2" 
                                onClick={ok}
                            >
                                {okButton || "OK"}
                            </Button>
                            <Button 
                                variant="danger" 
                                className="w-100 my-2" 
                                onClick={cancel}
                            >
                                {cancelButton || "CANCELAR"}
                            </Button>
                        </div>
                    </Container>
                    : <Fragment>
                        { closeButton || retryButton
                        ? (
                            <Container>
                                <div>
                                    {msg}
                                </div>
                                <Button 
                                    variant="primary" 
                                    className="w-100 mt-4 mb-2" 
                                    onClick={closeButtonFunc}
                                >
                                    {closeButtonText}
                                </Button>
                            </Container>
                        )
                        : (
                            <div>{msg}</div>
                        )
                        }
                    </Fragment>
                    }
                </div>       
                </CSSTransition>
            </SwitchTransition>
        </Fragment>
    );
}
 
export default InfoModalContent;