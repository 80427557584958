import React from 'react';
import Table from 'react-bootstrap/Table';

import ItemsStatus from './ItemsStatus';

const ItemsTable = ({packingOrder, packedItems}) => {
    return (
        <Table bordered hover>
            <thead>
                <tr>
                    <th>
                        SKU
                    </th>
                    <th>
                        Producto
                    </th>
                    <th>
                        Ítems
                    </th>
                    <th>
                        Estado
                    </th>
                </tr>
            </thead>
            <tbody>
                {packingOrder.items.map((item) => (
                    <tr key={item._id}>
                        <td style={{ width: "14rem" }}>
                            {item.variant.sku}<br />
                        </td>
                        <td>
                            {item.name}
                        </td>
                        <td style={{ width: "6rem" }}>
                            {item.quantity || 0}{item.quantityRefunded ? " (-" + item.quantityRefunded + ")" : null}
                        </td>
                        <td className="h3">
                            <ItemsStatus
                                itemQuantity={item.quantityToPack || 0}
                                itemQuantityOk={(packedItems.find((packedItem) => packedItem._id === item._id).packed) || 0}
                                itemQuantityDamaged={(packedItems.find((packedItem) => packedItem._id === item._id).damaged) || 0}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
 
export default ItemsTable;