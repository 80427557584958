import Badge from 'react-bootstrap/Badge';
import { FaNetworkWired, FaUsb, FaPrint } from 'react-icons/fa';

const getPrinterIcon = (printerConnection) => {
    switch (printerConnection) {
        case "network":
            return <FaNetworkWired style={{fontSize: "2rem"}}/>;
        case "local":
            return <FaUsb style={{fontSize: "2rem"}}/>;
        default:
            return <FaPrint style={{fontSize: "2rem"}}/>;
    }
};

const PrinterDetails = ({ printer, isAvailable }) => {
    return (
        <div className={"d-flex " + (isAvailable ? "printerOnline" : "printerOffline")}>
            <div className="me-2 w-25">
                {getPrinterIcon(printer.connection)}
            </div>
            <div className="w-75">
                {printer.name}
            </div>
            <Badge className="ms-2 d-flex align-items-center" bg="light" text="dark">
                {printer.uid}
            </Badge>
        </div>
    );
};

export default PrinterDetails;