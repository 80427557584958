import Stack from "react-bootstrap/Stack";

const ErrorWithTrace = ({msg, traceList}) => {
    return (
        <div>
            {msg}<br />
            <br />
            <Stack gap={1}>
                {traceList.map((content, index) => 
                    <div className={"p-2 " + (index % 2 === 0 ? "bg-light" : "")} key={"trace-" + index}>
                        <pre>{content}</pre>
                    </div>
                )}
            </Stack>
        </div>
    );
};

export default ErrorWithTrace;