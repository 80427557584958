import React, { useRef, useEffect, useState } from 'react';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const AddItemForm = ({itemSku, validated, handleChange, handleSubmit}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        const focus = () => {
            if (inputRef.current) {
                inputRef.current.focus();
                //console.log("Input focus", inputRef.current.value);
            }
        }
        setTimeout(focus, 100);
    }, []);

    const [key, setKey] = useState('packed');

    return (
        <Container style={{ minHeight: "8rem" }}>
            <Tabs
                id="packItemsTabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
            <Tab eventKey="packed" title="Agregar ítem">
                <div className="w-100 h-100 p-4 inputItemForm">
                    <Form
                        className="h-100"
                        validated={validated}
                        onSubmit={(event) => handleSubmit(event, "packed")}
                    >
                        <Row>
                            <Col>
                                <Form.Label>
                                    Agregar ítem
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm={10} controlId="inputItemSku">
                                <Form.Control
                                    required
                                    ref={inputRef}
                                    name="itemSku"
                                    type="text"
                                    placeholder="SKU"
                                    value={itemSku}
                                    onChange={(event) => handleChange(event, "packed")}
                                    maxLength={24}
                                />
                            </Form.Group>
                            <Col sm={2}>
                                <Button className="w-100" type="submit" variant="primary">
                                    Agregar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Tab>
            <Tab eventKey="damaged" title="Registrar dañado">
                <div className="w-100 h-100 p-4 inputItemForm">
                <Form
                        className="h-100"
                        validated={validated}
                        onSubmit={(event) => handleSubmit(event, "damaged")}
                    >
                        <Row>
                            <Col>
                                <Form.Label>
                                    Registrar ítem dañado
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm={10} controlId="inputDamagedSku">
                                <Form.Control
                                    required
                                    name="damagedSku"
                                    type="text"
                                    placeholder="SKU DAÑADO"
                                    value={itemSku}
                                    onChange={(event) => handleChange(event, "damaged")}
                                    maxLength={24}
                                />
                            </Form.Group>
                            <Col sm={2}>
                                <Button className="w-100" type="submit" variant="danger">
                                    Agregar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Tab>
            </Tabs>
            
        </Container>
    );
}
 
export default AddItemForm;