export const filterGroupToQuery = (paramName, filterGroup) => {
    const values = filterGroup
        .filter((item) => item.value === true)
        .map((item) => item.id);

    let params = "";
    for (const value of values) {
        params += paramName + "=" + encodeURIComponent(value) + "&";
    }
    return params;
};

export const filterDateToQuery = (paramName, filterDate) => {
    let query = "";

    let params = ["range"];
    if (filterDate && (filterDate["range"] === "custom" || filterDate["range"] === "range")) {
        params.push("from", "until");
    }

    for (const param of params) {
        if (filterDate[param]) {
            const paramCapitalized =
                param.charAt(0).toUpperCase() + param.slice(1);
            query +=
                paramName +
                paramCapitalized +
                "=" +
                encodeURIComponent(filterDate[param]) +
                "&";
        }
    }

    return query;
};

export const sortConfigToQuery = (sortConfig) => {
    const params =
        "sortKey=" +
        encodeURIComponent(sortConfig.key) +
        "&sortDirection=" +
        encodeURIComponent(sortConfig.direction) +
        "&";
    return params;
};

export const chunks = (a, size) =>
    Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
        a.slice(i * size, i * size + size)
    );
