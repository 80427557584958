import { useState, useMemo } from 'react';

const sortByBultosMarcaSku = (sortConfig, a, b) => {
  if (typeof(a['items']) === 'object' && typeof(b['items']) === 'object') {
    if (a['items'].length < b['items'].length) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    else if (a['items'].length > b['items'].length) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    else {
      const marcaA = a['brand'] ? a['brand'] : '';
      const marcaB = b['brand'] ? b['brand'] : '';
      //console.log("marcaA:", marcaA, ", marcaB:", marcaB);

      if (marcaA < marcaB) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      else if (marcaA > marcaB) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      else {
        const skuA = a['items'][0] && a['items'][0].variant && a['items'][0].variant.sku 
          ? a['items'][0].variant.sku 
          : '';

        const skuB = b['items'][0] && b['items'][0].variant && b['items'][0].variant.sku
          ? b['items'][0].variant.sku 
          : '';
        //console.log("skuA:", skuA, ", skuB:", skuB);

        if (skuA < skuB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        else if (skuA > skuB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        else {
          return 0;
        }
      }
    }
  }
  else {
    return 0;
  }
}

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    
    const sortedItems = useMemo(() => {
      let sortableItems = items ? [...items] : [];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          const key = sortConfig.key;
          if (key === 'items') {
            return sortByBultosMarcaSku(sortConfig, a, b);
          }
          else {
            if (a[key] < b[key]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
          }
          return 0;
        });
      }

      sortableItems = sortableItems.map((item, index) => ({
        ...item,
        sortedIndex: index,
      }));

      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = key => {
      let direction = 'ascending';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    }

    return { items: sortedItems, requestSort, sortConfig };
}

export default useSortableData;