import React, { useCallback, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaBarcode, FaDownload, FaPrint } from 'react-icons/fa';

import axios from "axios";
import fileDownload from "js-file-download";
import { Buffer } from "buffer";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";
import { PrinterContext } from '../../context/PrinterContext';

import { getFileExtensionFromContentType } from "../../helpers/File";

import ErrorWithTrace from '../Layout/ErrorWithTrace';

const ShippingLabel = ({ orderId, orderOrigin, orderShop, shippingType, setInfoModalData }) => {
    const { env, csrfToken } = useContext(SiteContext);
    const { user } = useContext(UserContext);
    const { canPrint, printZpl } = useContext(PrinterContext);

    const [loading, setLoading] = useState(false);
    const [labels, setLabels] = useState([]);

    const [downloadingLabel, setDownloadingLabel] = useState([]);
    const [printingLabel, setPrintingLabel] = useState([]);

    const showInfoModal = useCallback(
        (infoModalData) => {
            setInfoModalData({
                ...infoModalData,
                show: true
            });
        }, [setInfoModalData]
    );

    const downloadLabelAsFile = useCallback(
        (orderId, labelData) => {
            const contentType = labelData.contentType;
            const contentBase64 = labelData.contentBase64;
            if (contentBase64) {
                const labelTag = labelData._id || Date.now();
                const fileBuff = Buffer.from(contentBase64, "base64");

                const fileExtension = getFileExtensionFromContentType(contentType);
                const fileName = "labels-" + orderId + "-" + labelTag + '.' + fileExtension;
                fileDownload(fileBuff, fileName, contentType);
            }
        }, []
    );

    const downloadShippingLabel = useCallback(
        (labelId) => {
            setDownloadingLabel(downloadingLabel => ([
                ...downloadingLabel,
                labelId,
            ]));

            axios.get(
                env.webApiBaseUrl + "/orders/" + orderId + "/shippingLabels/" + labelId,
                {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                    timeout: 60000,
                }
            )
            .then ((response) => {
                downloadLabelAsFile(orderId, response.data);
            })
            .catch ((e) => {
                console.error(
                    "Problemas al descargar label: ",
                    e
                );

                const msg = e.message;
                showInfoModal({
                    type: "error",
                    size: "lg",
                    msg: <ErrorWithTrace 
                        msg="Problemas al descargar las etiquetas:"
                        traceList={[msg]}
                    />
                });
            })
            .finally(() => {
                setDownloadingLabel(downloadingLabel => ([
                    ...downloadingLabel.filter(l => l !== labelId),
                ]));
            });
        },
        [csrfToken, downloadLabelAsFile, env.webApiBaseUrl, orderId, showInfoModal, user.token],
    );
    
    const printShippingLabel = useCallback(
        (labelId) => {
            setPrintingLabel(printingLabel => ([
                ...printingLabel,
                labelId,
            ]));

            axios.get(
                env.webApiBaseUrl + "/orders/" + orderId + "/shippingLabels/" + labelId + "/zpl",
                {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                    timeout: 60000,
                }
            )
            .then ((response) => {
                const contentType = response.data.contentType;
                const contentBase64 = response.data.contentBase64;

                if (contentType !== "text/plain") {
                    showInfoModal({
                        type: "error",
                        size: "md",
                        msg: "Formato de etiquetas no soportado: " + contentType,
                    });
                }
                else if (!canPrint()) {
                    showInfoModal({
                        type: "error",
                        size: "md",
                        msg: "La impresión no está disponible en este momento. Se descargará archivo con etiquetas.",
                    });

                    downloadLabelAsFile(orderId, response.data);
                }
                else {
                    const fileBuff = Buffer.from(contentBase64, "base64");
                    printZpl(orderOrigin, orderShop, shippingType, fileBuff.toString())
                        .catch(e => {
                            const msg = e.message;
                            showInfoModal({
                                type: "error",
                                size: "md",
                                msg: <ErrorWithTrace 
                                    msg="Problemas al imprimir. Se descargará archivo con etiquetas."
                                    traceList={[msg]}
                                />
                            });

                            downloadLabelAsFile(orderId, response.data);
                        });
                }
            })
            .catch ((e) => {
                console.error(
                    "Problemas al imprimir label: ",
                    e
                );

                const msg = e.message;
                showInfoModal({
                    type: "error",
                    size: "lg",
                    msg: <ErrorWithTrace 
                        msg="Problemas al imprimir las etiquetas:"
                        traceList={[msg]}
                    />
                });
            })
            .finally(() => {
                setPrintingLabel(printingLabel => ([
                    ...printingLabel.filter(l => l !== labelId),
                ]));
            });
        },
        [canPrint, csrfToken, downloadLabelAsFile, env.webApiBaseUrl, orderId, orderOrigin, orderShop, printZpl, shippingType, showInfoModal, user.token],
    );

    const getShippingLabels = useCallback(
        () => {
            setLoading(true);

            axios.get(
                env.webApiBaseUrl + "/orders/" + orderId + "/shippingLabels",
                {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                    timeout: 60000,
                }
            )
            .then ((response) => {
                setLabels(response.data);
            })
            .catch ((e) => {
                console.error(
                    "Problemas al obtener los labels: ",
                    e
                );
            })
            .finally(() => {
                setLoading(false);
            });
        },
        [csrfToken, env.webApiBaseUrl, orderId, user.token],
    );

    const popover =  (
        <Popover id="popover-label" className="labelsPopover">
            <Popover.Header as="h3">
                Etiquetas de despacho
            </Popover.Header>
            <Popover.Body className="me-2">
                {loading
                ? <div className="text-center"> 
                    <Spinner animation="border" role="status" size="sm"/>
                </div>
                : labels && labels.length > 0
                ? <ul>
                    {labels.map((label, index) => (
                        <li key={'label-' + index} className="mb-2">
                            <strong>{label.trackingNumber}</strong> <br/>
                            {(new Date(label.updatedAt)).toLocaleString('es-CL')} <br/>
                            
                            {label.carrier && label.carrier !== "centry"
                            ? <span>transportista <em>{label.carrier}</em><br/></span>
                            : null
                            }

                            <Row className="mt-2">
                                <Col md={6} className="px-1">
                                    <Button 
                                        className="w-100"
                                        size="sm" 
                                        variant="warning" 
                                        disabled={downloadingLabel.length > 0}
                                        onClick={() => downloadShippingLabel(label._id)} 
                                    >
                                        {downloadingLabel.find(l => l === label._id)
                                        ? <Spinner animation="border" role="status" size="sm"/>
                                        : <span><FaDownload className="me-2" />Descargar</span>
                                        }
                                    </Button>
                                </Col>
                                <Col md={6} className="px-1">
                                    {canPrint()
                                    ? <Button 
                                        className="w-100"
                                        size="sm" 
                                        variant="primary" 
                                        disabled={printingLabel.length > 0}
                                        onClick={() => printShippingLabel(label._id)} 
                                    >
                                        {printingLabel.find(l => l === label._id)
                                        ? <Spinner animation="border" role="status" size="sm"/>
                                        : <span><FaPrint className="me-2" />Imprimir</span>
                                        }
                                    </Button>
                                    : null
                                    }
                                </Col>
                            </Row>
                        </li>
                    ))}
                </ul>
                : <div className="text-center">
                    La venta no tiene etiquetas registradas
                </div>
                }
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
            <Button size="sm" variant="primary" onClick={getShippingLabels}>
                <FaBarcode className="lead"/>
            </Button>
        </OverlayTrigger>
    );
}
 
export default ShippingLabel;