import React from 'react'
import Modal from 'react-bootstrap/Modal';

import InfoModalContent from './InfoModalContent';

const InfoModal = ({ type, size, msg, setMsg, closeButton, retryButton, okButton, cancelButton, ok, cancel, close, retry, show }) => {

    const showModal = typeof(show) !== "undefined" ? show : msg !== '' && msg != null;

    return (
        <Modal
            className="infoModal"
            animation={false}
            size={size ? size : "sm"}
            centered
            backdrop="static"
            show={showModal}
        >
            <Modal.Body className="text-center">
                <InfoModalContent
                    type={type}
                    closeButton={closeButton}
                    retryButton={retryButton}
                    msg={msg}
                    setMsg={setMsg}
                    ok={ok}
                    cancel={cancel}
                    close={close}
                    retry={retry}
                    size={size}
                    okButton={okButton}
                    cancelButton={cancelButton}
                />
            </Modal.Body>
        </Modal>
    );
}
 
export default InfoModal;