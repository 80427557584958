import React, { useCallback, useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";

import DateRangeFilter from "./Filters/DateRangeFilter";
import CreditNoteDateRangeFilter from "./Filters/CreditNoteDateRangeFilter";

import { SiteContext } from "../../context/SiteContext";

const OrderFilters = ({ orderFilters, setOrderFilters }) => {
    const { setSalesShopGroup } = useContext(SiteContext);

    const [filters, setFilters] = useState(orderFilters);
    const [filteringEnabled, setFilteringEnabled] = useState(true);

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            setOrderFilters(filters);

            if (filters.shopGroups) {
                const selectedShopGroup = filters.shopGroups.find((shopGroup) => shopGroup.value);
                //console.log("Setting sales shopGroup: ", selectedShopGroup.id);
                setSalesShopGroup(selectedShopGroup.id);
            }
        },
        [filters, setOrderFilters, setSalesShopGroup]
    );

    const handleRadioChange = useCallback((group, id, value) => {
        //console.log("Updating filter: ", group, id);

        setFilters((oldFilters) => {
            const newFilters = {
                ...oldFilters,
                [group]: oldFilters[group].map((item) => ({
                    id: item.id,
                    name: item.name,
                    value: item.id === id ? value : false,
                })),
            };

            //console.log("New filters: ", newFilters);
            return newFilters;
        });
    }, []);

    const handleChange = useCallback((group, id, value) => {
        //console.log("Updating filter: ", group, id, value);

        setFilters((oldFilters) => {
            const newFilters = {
                ...oldFilters,
                [group]: oldFilters[group].map((item) => ({
                    id: item.id,
                    name: item.name,
                    value: item.id === id ? value : item.value,
                })),
            };

            //console.log("New filters: ", newFilters);

            return newFilters;
        });
    }, []);

    const handleSelectAllGroup = useCallback(
        (group) => {
            //console.log("Filters: ", filters);

            const selected = (filters[group] || []).filter(
                (item) => item.value === true
            ).length;

            if (filters[group] && filters[group].length === selected) {
                filters[group].forEach((item) => {
                    handleChange(group, item.id, false);
                });
            } else if (filters[group]) {
                filters[group].forEach((item) => {
                    handleChange(group, item.id, true);
                });
            }
        },
        [filters, handleChange]
    );

    useEffect(() => {
        const checkFilterButtonEnabled = () => {
            if (orderFilters.groups) {
                for (const group of orderFilters.groups) {
                    let hasItemsSelected = false;
                    for (const item of filters[group]) {
                        if (item.value) {
                            hasItemsSelected = true;
                            break;
                        }
                    }

                    if (!hasItemsSelected) {
                        setFilteringEnabled(false);
                        return;
                    }
                }
            }

            setFilteringEnabled(true);
        };
        checkFilterButtonEnabled();
    }, [filters, orderFilters.groups]);

    useEffect(() => {
        setFilters(orderFilters);
    }, [orderFilters])

    return (
        <div className="orderFilters w-100 h-100 p-3 pt-1">
            <Form
                className="h-100"
                validated={false}
                onSubmit={(event) => handleSubmit(event)}
            >
                <Row className="mt-3">
                    <Col md={11}>
                        <Form.Group className="form-group">
                            <Form.Label>Origen</Form.Label>
                            <Row>
                                {filters.shopGroups.map((shopGroup) => (
                                    <Col md key={"shopGroup_" + shopGroup.id}>
                                        <Form.Check
                                            type="radio"
                                            className=""
                                            name="shopGroup"
                                            id={"filterOrigin_" + shopGroup.id}
                                            label={shopGroup.name}
                                            checked={shopGroup.value}
                                            disabled={shopGroup.disabled || false}
                                            onChange={(event) =>
                                                handleRadioChange(
                                                    "shopGroups",
                                                    shopGroup.id,
                                                    event.currentTarget.checked
                                                )
                                            }
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col className="align-self-start text-end" md={1}>
                        <Button
                            className="w-100"
                            type="submit"
                            variant="primary"
                            disabled={!filteringEnabled}
                        >
                            <FaArrowRight />
                        </Button>
                    </Col>
                </Row>

                <Row className="mt-3">
                    {orderFilters.dateRangeOptions ? (
                        <Col md={2}>
                            <DateRangeFilter
                                filters={filters}
                                setFilters={setFilters}
                                dateRangeOptions={orderFilters.dateRangeOptions}
                            />
                        </Col>
                    ) : null}

                    {orderFilters.creditNoteDateRangeOptions ? (
                        <Col md={2}>
                            <CreditNoteDateRangeFilter
                                filters={filters}
                                setFilters={setFilters}
                                creditNoteDateRangeOptions={orderFilters.creditNoteDateRangeOptions}
                            />
                        </Col>
                    ) : null}


                    {filters.shippingTypes
                    ? <Col md={3}> 
                        <Form.Group className="form-group">
                            <Form.Label>
                                Tipos de envío
                                <Form.Check
                                    inline
                                    type="checkbox"
                                    className="ms-2 pt-1 pe-0"
                                    id="tipoEnvioAll"
                                    checked={
                                        filters["shippingTypes"].filter(
                                            (item) => item.value === true
                                        ).length ===
                                        filters["shippingTypes"].length
                                    }
                                    onChange={(event) =>
                                        handleSelectAllGroup("shippingTypes")
                                    }
                                />
                            </Form.Label>

                            <Row>
                                {filters.shippingTypes.map((shippingType) => (
                                    <Col
                                        xs={4}
                                        key={
                                            "shippingTypeCol_" + shippingType.id
                                        }
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            className=""
                                            id={
                                                "filterShippingType_" +
                                                shippingType.id
                                            }
                                            label={shippingType.name}
                                            checked={shippingType.value}
                                            onChange={(event) =>
                                                handleChange(
                                                    "shippingTypes",
                                                    shippingType.id,
                                                    event.currentTarget.checked
                                                )
                                            }
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Form.Group>
                        </Col>
                    : null }

                    {filters.statuses ? (
                        <Col md={2}>
                            <Form.Group className="form-group">
                                <Form.Label>Estados</Form.Label>

                                {filters.statuses.map((status) => (
                                    <Form.Check
                                        key={"status_" + status.id}
                                        type="checkbox"
                                        className=""
                                        id={"filterStatus_" + status.id}
                                        label={status.name}
                                        checked={status.value}
                                        onChange={(event) =>
                                            handleChange(
                                                "statuses",
                                                status.id,
                                                event.currentTarget.checked
                                            )
                                        }
                                    />
                                ))}
                            </Form.Group>
                        </Col>
                        ) : null}

                        {filters.partialStockStatuses ? (
                        <Col>
                            <Form.Group className="form-group">
                                <Form.Label>Estado faltantes</Form.Label>

                                {filters.partialStockStatuses.map((status) => (
                                    <Form.Check
                                        key={"partialStockStatus_" + status.id}
                                        type="radio"
                                        className=""
                                        id={"filterPartialStockStatus_" + status.id}
                                        label={status.name}
                                        checked={status.value}
                                        onChange={(event) =>
                                            handleRadioChange(
                                                "partialStockStatuses",
                                                status.id,
                                                event.currentTarget.checked
                                            )
                                        }
                                    />
                                ))}
                            </Form.Group>
                        </Col>
                        ) : null}

                    {filters.brands ? (
                        <Col md={5}>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Marcas
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        className="ms-2 pt-1 pe-0"
                                        id="marcaAll"
                                        label={null}
                                        checked={
                                            filters["brands"].filter(
                                                (item) => item.value === true
                                            ).length ===
                                            filters["brands"].length
                                        }
                                        onChange={(event) =>
                                            handleSelectAllGroup("brands")
                                        }
                                    />
                                </Form.Label>

                                <Row>
                                    {filters.brands.map((brand) => (
                                        <Col
                                            xs={4}
                                            key={"brandCol_" + brand.id}
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                className=""
                                                id={"filterBrand_" + brand.id}
                                                label={brand.name}
                                                checked={brand.value}
                                                onChange={(event) =>
                                                    handleChange(
                                                        "brands",
                                                        brand.id,
                                                        event.currentTarget
                                                            .checked
                                                    )
                                                }
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Form.Group>
                        </Col>
                    ) : null}
                </Row>
            </Form>
        </div>
    );
};

export default OrderFilters;
