import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaBan } from "react-icons/fa";

const SearchOrderModalHeader = ({handleCancel}) => {
    return (
        <Modal.Header>
            <Modal.Title className="flex-grow-1">
                CREAR NOTA DE CRÉDITO
            </Modal.Title>
            <div>
                <Button
                    variant="danger"
                    className="float-end ms-2 mt-1 mb-2"
                    onClick={handleCancel}
                >
                    <FaBan style={{ marginBottom: "3px" }} />
                </Button>
            </div>
        </Modal.Header>
    );
}
 
export default SearchOrderModalHeader;