import React, { useEffect, useContext, useState, useCallback } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { FaSync } from "react-icons/fa";

import SubHeader from "../Layout/SubHeader";
import OrdersTable from "../Orders/OrdersTable";
import InfoModal from "../Layout/InfoModal";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import useSortableData from "../../hooks/SortableData";

import { filterGroupToQuery, filterDateToQuery } from "../../helpers/Query";
import { getDateLimitedOrderFiltersSchema } from "../../helpers/Filters";

const Processed = () => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [lastLoadTime, setLastLoadTime] = useState(new Date());

    const [infoModalData, setInfoModalData] = useState(null);
    const [itemQuantity, setItemQuantity] = useState(0);

    const { items, requestSort, sortConfig } = useSortableData(orders, {
        direction: "ascending",
        key: "createdAt",
    });

    const [orderFilters, setOrderFilters] = useState({
        ...getDateLimitedOrderFiltersSchema(salesShopGroup),
        statuses: [
            { id: "received", name: "received", value: false },
            { id: "shipped", name: "shipped", value: true },
        ],
    });

    const fetchOrders = useCallback(() => {
        setOrdersLoading(true);

        let queryFilters = "?group=processed&";
        queryFilters += filterGroupToQuery("status", orderFilters.statuses);
        queryFilters += filterGroupToQuery("brand", orderFilters.brands);
        queryFilters += filterGroupToQuery(
            "shippingType",
            orderFilters.shippingTypes
        );
        queryFilters += filterDateToQuery("date", orderFilters.date);
        queryFilters += filterGroupToQuery("shopGroups", orderFilters.shopGroups);

        axios
            .get(env.webApiBaseUrl + "/orders" + queryFilters, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
            })
            .then((ordersResponse) => {
                setOrders(ordersResponse.data);
            })
            .catch((error) => {
                console.error(error);
                setInfoModalData({
                    type: "error",
                    msg: "No pude recuperar las ventas.",
                });
            })
            .finally(() => {
                setOrdersLoading(false);
                setLastLoadTime(new Date());
            });
    }, [env, csrfToken, user, setOrdersLoading, setOrders, orderFilters]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders, orderFilters]);

    useEffect(() => {
        setItemQuantity(orders.reduce((a,b) => a + b.items.reduce((x,y) => x + y.quantity, 0), 0));
    }, [orders]);

    return (
        <div>
            <SubHeader>
                <Button
                    className="float-end ms-2 mt-1 mb-2"
                    variant="primary"
                    onClick={() => fetchOrders()}
                >
                    <FaSync style={{ marginBottom: "3px" }} />
                </Button>
                <h2>
                    VENTAS PROCESADAS
                    <span className="quantities">
                        <Badge className="ms-3" bg="dark">
                            {orders.length} ventas
                        </Badge>
                        <Badge className="ms-2" bg="info">
                            {itemQuantity} ítems
                        </Badge>
                    </span>
                </h2>
                Última actualización: {lastLoadTime.toLocaleString("es-CL")}
            </SubHeader>
            <br />

            <Container fluid>
                {infoModalData ? (
                    <InfoModal
                        closeButton={true}
                        type={infoModalData.type}
                        size={infoModalData.size}
                        msg={infoModalData.msg}
                        setMsg={setInfoModalData}
                    />
                ) : null}

                <OrdersTable
                    orders={items}
                    sortConfig={sortConfig}
                    requestSort={requestSort}
                    orderFilters={orderFilters}
                    setOrderFilters={setOrderFilters}
                    ordersLoading={ordersLoading}
                    setInfoModalData={setInfoModalData}
                />
            </Container>
        </div>
    );
};

export default Processed;
