import React, { useContext } from 'react';

import AutoPackings from './AutoPackings';
import ManualPackings from './ManualPackings';

import { SiteContext } from "../../context/SiteContext";

const Packings = () => {
    const { pdfLabelPrintEnabled, salesShopGroup } = useContext(SiteContext);

    const autoPackingsEnabled = pdfLabelPrintEnabled ||
        (salesShopGroup === "PZ" || salesShopGroup === "MercadoLibre")

    return (
        <>
        {autoPackingsEnabled
        ? <AutoPackings />
        : <ManualPackings />
        }
        </>
    );
}

export default Packings;