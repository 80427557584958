import React from "react";
import { FaMinusSquare } from "react-icons/fa";
import { FaCheckSquare } from "react-icons/fa";

const OrderItemPackingStatus = ({ item }) => {
    const quantityPacked = item.quantityPacked && item.quantityPacked >= 0 ? item.quantityPacked : 0;
    const quantityDamaged = item.quantityDamaged && item.quantityDamaged >= 0 ? item.quantityDamaged : 0;
    const quantityOutOfStock = item.quantityOutOfStock && item.quantityOutOfStock >= 0 ? item.quantityOutOfStock : 0;

    return (
        <div className="lead">
            {[...Array(quantityPacked)].map((elem, index) => (
                <FaCheckSquare
                    key={item.idOriginVariant + "check" + index}
                    className="text-success"
                />
            ))}
            {[...Array(quantityDamaged)].map((elem, index) => (
                <FaMinusSquare
                    key={item.idOriginVariant + "damag" + index}
                    style={{ color: "purple" }}
                />
            ))}
            {[...Array(quantityOutOfStock)].map((elem, index) => (
                <FaMinusSquare
                    key={item.idOriginVariant + "minus" + index}
                    className="text-danger"
                />
            ))}
        </div>
    );
};

export default OrderItemPackingStatus;
