import React from 'react';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { FaSquare } from 'react-icons/fa';
import { FaCheckSquare } from 'react-icons/fa';
import { FaMinusSquare } from 'react-icons/fa';

const ItemsStatus = ({itemQuantity, itemQuantityOk, itemQuantityDamaged}) => {
    if (!itemQuantity || itemQuantity < 0) {
        return null;
    }

    const itemStatus = [...Array(itemQuantity)].map((status, index) => {
        if (index < itemQuantityOk) {
            return 1;
        }
        else if (index >= itemQuantityOk && index < itemQuantityOk + itemQuantityDamaged) {
            return 2;
        }
        else {
            return 0;
        }
    });

    //console.log("itemStatus: ", itemStatus);

    return (
        <span>
            {itemStatus.map((status, index) => 
                <SwitchTransition key={"status_" + index} mode="out-in">
                    <CSSTransition
                        key={status}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        classNames="changeStatus"
                    >
                        {
                            {
                                0: <FaSquare style={{ color: "lightgray" }}/>,
                                1: <FaCheckSquare className="text-success"/>,
                                2: <FaMinusSquare style={{ color: "purple" }}/>
                            }[status]
                        }
                    </CSSTransition>
                </SwitchTransition>
            )}
        </span>
    );
}
 
export default ItemsStatus;