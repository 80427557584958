import React, { useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { PrinterContext } from '../../context/PrinterContext';

const CheckBrowserPrint = ({ setInfoModalData }) => {
    const { printingEnabled, printer, canPrint } = useContext(PrinterContext);

    return (
        <Container>
            <div className="w-100 p-4 border">
                <Button 
                    className="float-end"
                    variant="warning"
                    onClick={() => {window.open("https://localhost:9101/ssl_support")}}
                >
                    Revisar certificado
                </Button>
                
                <h5>
                    Estado de BrowserPrint
                </h5>

                <Row>
                    <Col md={12}>
                        Printing enabled: {printingEnabled ? "Y" : "N"}
                    </Col>
                    <Col md={12}>
                        Printer: <pre style={{ whiteSpace: "break-spaces" }}>{JSON.stringify(printer, null, 4)}</pre>
                    </Col>
                    <Col md={12}>
                        CanPrint: {canPrint() ? "Y" : "N"}
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default CheckBrowserPrint;